import { useState } from 'react';
import ExchangeService from '../services/ExchangeService';

const createExchangeService = (authToken: string) =>
  new ExchangeService(() => authToken);

const useExchangeService = (
  initialAuthToken: string
): readonly [ExchangeService] => {
  const [authToken] = useState(initialAuthToken);
  const [exchangeService] = useState(createExchangeService(authToken));

  return [exchangeService] as const;
};

export default useExchangeService;
