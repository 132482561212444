import { CardTitle } from '@ekino/serenly-ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ExchangeSender } from '../../clients/ApiClient';

interface DownloadPreviewHeaderProps {
  sender: ExchangeSender;
  documentsCount?: number;
  expirationDate?: string;
  verificationSMSRequired: boolean;
}

function DownloadPreviewHeader({
  sender,
  documentsCount,
  verificationSMSRequired,
}: DownloadPreviewHeaderProps) {
  const { t } = useTranslation();

  return (
    <>
      <CardTitle
        title={t('downloadView.heading', {
          name: sender.full_name,
          count: documentsCount,
        })}
        passwordIntroduction={t('exchangeFiles.protectedTransfer')}
        protectedBy={{
          password: true,
          sms: verificationSMSRequired || false,
        }}
      />
    </>
  );
}

export default styled(DownloadPreviewHeader)``;
