import { useState } from 'react';
import styled from 'styled-components';
import { Background, Navigation } from '@ekino/serenly-ui';
import { ekinoBackground } from '../../assets/images';
import Header from './Header';
import Footer from './Footer';
import { useTranslation } from 'react-i18next';

const AuthenticatedLayout = ({ children, ...rest }: any) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const secondNav = [
    {
      to: '/legal-notices',
      text: t('loginpageView.links.legalMentions'),
    },
    { to: '/terms-of-service', text: t('loginpageView.links.tos') },
    {
      to: '/privacy-policy',
      text: t('loginpageView.links.privateCharte'),
    },
  ];

  return (
    <Navigation
      open={menuOpen}
      secondNav={
        <Navigation.SecondNav>
          {secondNav.map(nav => (
            <a
              key={nav.text}
              href={nav.to}
              title={nav.text}
              target="_blank"
              rel="noreferrer"
            >
              {nav.text}
            </a>
          ))}
        </Navigation.SecondNav>
      }
    >
      <Background image={ekinoBackground} {...rest}>
        <Header
          menuOpen={menuOpen}
          onToggleMenu={() => setMenuOpen(!menuOpen)}
        />
        {children}
        <Footer />
      </Background>
    </Navigation>
  );
};

export default styled(AuthenticatedLayout)`
  display: flex;
`;
