import { Routes, Route, Navigate } from 'react-router-dom';
import {
  RoutePath,
  unauthenticatedRoutes,
  commonRoutes,
} from './components/routes';
import UnauthenticatedLayout from './components/layout/UnauthenticatedLayout';

export default function UnauthenticatedApp() {
  const routes = commonRoutes.concat(unauthenticatedRoutes);

  return (
    <UnauthenticatedLayout>
      <Routes>
        {routes.map(route => {
          return (
            <Route
              index={RoutePath.LOGIN === route.path}
              path={route.path}
              element={<route.element />}
              key={route.name}
            />
          );
        })}
        <Route path="*" element={<Navigate to={RoutePath.LOGIN} />} />
      </Routes>
    </UnauthenticatedLayout>
  );
}
