import { Background } from '@ekino/serenly-ui';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { RoutePath } from '../routes';
import SidebarLeft from '../receiver/SidebarLeft';
import { ekinoBackground } from '../../assets/images';

function UnauthenticatedLayout({ children, ...rest }: any) {
  const { pathname } = useLocation();

  return (
    <div {...rest}>
      {pathname !== RoutePath.LOGIN && <SidebarLeft />}
      <Background image={ekinoBackground}>{children}</Background>
    </div>
  );
}

export default styled(UnauthenticatedLayout)`
  display: flex;
`;
