export const convertBytesToKb = (value: number) => value / 1000 + 'Kb';

export const convertFileSize = (size: number) => {
  let i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    Math.floor(size / Math.pow(1024, i)) * 1 +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  );
};
