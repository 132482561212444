import { RoutePath } from './path';
import UploadHomePage from '../views/UploadHomePage';
import UploadDetailsPage from '../views/UploadDetailsPage';
import GenerateLinkPage from '../views/GenerateLinkPage';
import TransferPage from '../views/TransferPage';
import { IRoute } from './commonRoutes';

export const authenticatedRoutes: IRoute[] = [
  {
    name: 'home',
    path: RoutePath.HOME,
    element: UploadHomePage,
  },
  {
    name: 'generate-link',
    path: RoutePath.GENERATE_LINK,
    element: GenerateLinkPage,
  },
  {
    name: 'upload',
    path: RoutePath.UPLOAD_PAGE,
    element: UploadDetailsPage,
  },
  {
    name: 'transfers',
    path: RoutePath.TRANSFERS,
    element: TransferPage,
  },
];

export default authenticatedRoutes;
