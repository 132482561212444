import styled from 'styled-components';

type DisclaimerLayoutProps = {
  children?: React.ReactNode;
};

const DisclaimerLayout = ({
  children,
  ...rest
}: DisclaimerLayoutProps): JSX.Element => (
  <div {...rest}>
    <div className="content">{children}</div>
  </div>
);

export default styled(DisclaimerLayout)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  height: 100vh;

  .content {
    padding: 2rem;
    max-width: 70vh;
    height: 100%;
    background-color: white;
    overflow: auto;
    border-radius: 20px;
    h1,
    h2 {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 10px;
    }

    h1 {
      text-align: center;
      font-size: 16px;
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 10px;
      max-width: 75em;
      text-align: left;
      font-size: 12px;
    }

    th {
      vertical-align: middle;
    }

    th:p {
      font-weight: bold;
    }
  }
`;
