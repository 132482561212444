/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { ChunkContent } from '../../services/EncryptionService';
import { Loader } from '@ekino/serenly-ui';
import { useTransfer } from '../../contexts/TransferContext';

export interface ExchangeFilesUploadProps {
  secret: string;
  documentCreateHandler: (fileMetadata: string) => Promise<string>;
  documentChunkCreateHandler: (
    documentId: string,
    chunk: ChunkContent,
    chunkIndex: number
  ) => Promise<void>;
  contentDecryptionKeyHandler: (contentDecryptionKey: string) => Promise<void>;
  transferName: string;
  filesSize: string;
  setModalCancelUpload: () => void;
}

const ExchangeFilesUpload = ({
  secret,
  documentCreateHandler,
  documentChunkCreateHandler,
  contentDecryptionKeyHandler,
  transferName,
  filesSize,
  setModalCancelUpload,
}: ExchangeFilesUploadProps): JSX.Element => {
  const { startUpload, globalProgress } = useTransfer();

  useEffect(() => {
    startUpload({
      secret,
      documentCreateHandler,
      documentChunkCreateHandler,
      contentDecryptionKeyHandler,
    });
  }, []);

  return (
    <Loader
      progress={globalProgress}
      debit={0}
      fileName={transferName}
      fileSize={filesSize}
      fileType={'ZIP'}
      onDeleteFile={setModalCancelUpload}
    />
  );
};

export default ExchangeFilesUpload;
