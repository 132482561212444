export enum RoutePath {
  HOME = '/',
  TRANSFERS = '/transfers',
  LOGIN = '/login',
  DASHBOARD = '/dashboard',
  ARCHIVES = '/archives',
  DOWNLOAD_PATH = '/download',
  DOWNLOAD = '/download/:shareTokenParam',
  USERS = '/users',
  LEGAL_NOTICES = '/legal-notices',
  TOS = '/terms-of-service',
  PRIVACY_POLICY = '/privacy-policy',
  COOKIE_POLICY = '/cookie-policy',
  UPLOAD_PAGE = '/upload',
  GENERATE_LINK = '/generate-link',
}

export default RoutePath;
