import { useEffect } from 'react';
import DisclaimerLayout from '../layout/DisclaimerLayout';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { PAGES } from '../../constants/tracking';

const LegalNotice = ({ ...rest }) => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: PAGES.LEGAL_NOTICE,
    });
  }, [trackPageView]);

  return (
    <DisclaimerLayout {...rest}>
      <h1>Informations légales</h1>

      <div>
        <h2>Éditeur</h2>
        <address>
          <p>
            La Plateforme SEND.EKINO.COM est éditée et exploitée par la société
            EKINO, société par actions simplifiée au capital de 296 000 Euros,
            dont le siège social est situé 157 rue Anatole France à LEVALLOIS
            PERRET (92300), inscrite au Registre du Commerce et des Sociétés de
            Nanterre sous le numéro SIREN n°509512760
          </p>
          <p>N°de TVA intracommunautaire : FR70509512760</p>
          <p>
            Numéro de téléphone : <a href="tel:+33149687300">0149687300</a> /
            e-mail de contact : contact@ekino.com
          </p>
          <p>Directeur de la publication : M. Malo Gaudry, Directeur Général</p>
        </address>
      </div>

      <div>
        <h2>Hébergement</h2>
        <p>
          Ce site est hébergé par : AMAZON (AWS)
          <br />
          Adresse :{' '}
          <span lang="en">
            Amazon Web Services, Inc. P.O. Box 81226 Seattle, WA 98108-1226 USA
          </span>
        </p>
      </div>
    </DisclaimerLayout>
  );
};

export default LegalNotice;
