/**
 * Split a file into multiple sub-blobs
 *
 * @param {Blob} file
 * @param {number} chunkSize
 * @returns Blob[]
 */
export const chunkFile = (file: Blob, chunkSize: number): Blob[] => {
  const chunksArray: Blob[] = [];

  for (let i = 0; i < file.size; i += chunkSize) {
    chunksArray.push(file.slice(i, i + chunkSize));
  }

  return chunksArray;
};

/**
 * Convert UintXArray to an ArrayBuffer
 *
 * @param {Uint8Array|Uint16Array|Uint32Array} array
 * @returns ArrayBuffer
 */
export const typedArrayToBuffer = (
  array: Uint8Array | Uint16Array | Uint32Array
): ArrayBuffer =>
  array.buffer.slice(array.byteOffset, array.byteLength + array.byteOffset);
