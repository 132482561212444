import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ButtonCircle, DragAndDrop } from '@ekino/serenly-ui';
import UploadCircleButton from '../sender/UploadCircleButton';
import { useTransfer } from '../../contexts/TransferContext';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../routes';
import { useTranslation } from 'react-i18next';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { PAGES } from '../../constants/tracking';

const UploadHomePage = ({ className, ...rest }: any) => {
  const [isVisible, setVisible] = useState(false);
  const { transfer, setTransfer, resetTransfer } = useTransfer();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    if (transfer.files.length) {
      resetTransfer();
    }
  }, [resetTransfer, transfer]);

  const handleAddFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      setTransfer({
        ...transfer,
        files: Array.from(files),
      });
    }

    navigate(RoutePath.UPLOAD_PAGE);
  };

  useEffect(() => {
    trackPageView({
      documentTitle: PAGES.HOME,
    });
  }, [trackPageView]);

  return (
    <div {...rest}>
      <DragAndDrop
        isVisible={isVisible}
        onDropFile={handleAddFiles}
        onDragEnter={() => setVisible(true)}
        onDragLeave={() => setVisible(false)}
        onDrop={() => setVisible(false)}
      >
        <div className={className}>
          <UploadCircleButton onChangeInputFile={handleAddFiles} />
          <div className="my-transfert-button">
            <ButtonCircle
              onClick={() => navigate(RoutePath.TRANSFERS)}
              typeIcon="Transfert"
              variantButtonCircle="secondary"
            >
              {t('uploadPage.button.myTransfer')}
            </ButtonCircle>
          </div>
        </div>
      </DragAndDrop>
    </div>
  );
};

export default styled(UploadHomePage)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .my-transfert-button {
    margin-left: -30px;
  }
`;
