import styled from 'styled-components';
import { Modal, Icon } from '@ekino/serenly-ui';
import { useTranslation } from 'react-i18next';

const StyledTitle = styled.h1`
  display: flex;
  gap: 10px;
  margin: 0 25px;
  font-size: 24px;
  font-weight: 500;
`;

const StyledText = styled.p`
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  text-align: center;
`;

type DocumentErrorProps = {
  isArchiveTransfer?: boolean;
};

const DocumentError = ({
  isArchiveTransfer,
}: DocumentErrorProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Modal
      open={true}
      closeIcon={false}
      zIndexActive={false}
      children={
        <>
          <StyledTitle>
            <Icon type="Warning" sizeIcon="medium" />
            <p>{t('exchangeDetails.error.unableToRetrieveExchange')}</p>
          </StyledTitle>
          {!isArchiveTransfer && (
            <StyledText>{t('exchangeDetails.error.attempts')}</StyledText>
          )}
          <StyledText>{t('exchangeDetails.error.contactSender')}</StyledText>
        </>
      }
    />
  );
};

export default DocumentError;
