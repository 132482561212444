const FR_FR = {
  downloadView: {
    heading: '{{name}} a partagé {{count}} document',
    heading_other: '{{name}} a partagé {{count}} documents',
    expiresIn: 'Expire dans {{days}}',
    loaderText: 'Fichiers en préparation',
  },
  exchangeFiles: {
    sidebarLeft: {
      introduction: 'ekino sécurise ses transferts avec Send.',
      list: [
        'Chiffrement de bout-en-bout',
        'Maîtrise des accès aux fichiers',
        'Double vérification du destinataire par SMS',
        'Transferts éphémères',
      ],
    },
    tooltip: {
      conditionsNoAccepted:
        "Vous n'avez pas validé les Conditions Générales d'Utilisation et la Charte de la vie Privée",
    },
    submit: 'Déverrouiller',
    protectedTransfer: 'Ce transfert est protégé par:',
    passwordIntroduction: 'Entrez le mot de passe',
    passwordInformation: 'Celui-ci vous a été fourni par {{sender}}',
    label: 'Mot de passe',
    error: {
      invalidPassword: 'Mot de passe incorrect',
      attemptRemaining:
        '$t(exchangeFiles.error.invalidPassword). {{count}} tentative restante',
      attemptRemaining_other:
        '$t(exchangeFiles.error.invalidPassword). {{count}} tentatives restantes',
      exhaustedAttempt:
        "Sorry, you've exhausted all attempts to unlock this link. This link is now inactive.<br />Please reach your contact to get a new link.",
    },
    sms: {
      introduction: 'Saisir le code envoyé au ....',
      sendCode: 'Recevoir un code par SMS',
      codeSent:
        "Un code a été envoyé sur votre numéro. Merci de l'utiliser pour vous identifier.",
      label: 'Code reçu',
      reSend: 'Renvoyer le code ?',
      submit: 'Suivant',
    },
  },
  exchangeDetails: {
    error: {
      unableToRetrieveExchange: 'Le transfert est indisponible',
      attempts: 'Vous avez utilisé toutes vos tentatives.',
      contactSender: 'Veuillez contacter votre expéditeur.',
    },
  },
  forms: {
    required: 'Requis',
    mustBe6Number: 'Doit être composé de 6 numéros',
  },
  loginpageView: {
    rules: {
      encryption: 'Chiffrement de bout-en-bout',
      fileAccess: 'Maîtrise des accès aux fichiers',
      twoFactorsVerification: 'Double vérification du destinataire par SMS',
      duration: 'Transferts éphémères',
    },
    buttons: {
      sso: 'Me connecter',
      google: 'Se connecter avec google',
    },
    links: {
      legalMentions: 'Mentions légales',
      tos: "Conditions générales d'utilisation",
      privateCharte: 'Charte de vie privée',
      cookies: 'Politique de cookies',
      contact: 'Nous contacter',
    },
  },
  shareToken: {
    error: "Impossible de récupérer le jeton d'accès",
  },
  transferPageView: {
    tabs: {
      active: 'Mes transferts en cours',
      archive: 'Mes transferts clos',
    },
    modal: {
      text: 'Êtes vous certains de vouloir archiver cette échange ?',
      buttons: {
        archive: 'Archiver',
        cancel: 'Annuler',
      },
    },
  },
  uploadPage: {
    tabs: {
      link: 'Créer un lien',
      email: 'Envoyer par e-mail',
    },
    password: {
      header: 'Nom du transfert',
      placeholderName: 'Nom du transfert',
      placeholderPassword: 'Créer un mot de passe',
    },
    receiver: {
      header: 'Mes destinataires',
    },
    date: {
      header: 'Durée de vie du transfert',
    },
    button: {
      sendFile: 'Envoyer un fichier',
      myTransfer: 'Mes transferts',
      generate: 'Générer mon lien',
      transfer: 'Transférer mon fichier',
    },
    modal: {
      buttons: {
        cancel: 'Annuler',
        delete: 'Supprimer',
        gotIt: "J'ai compris",
        edit: 'Modifier',
        add: 'Ajouter',
        deleteNum: 'Supprimer le n°',
      },
      textContent: {
        deleteFile: 'Êtes-vous certains de vouloir supprimer',
        transferInProgress:
          'Vous souhaitez aller consulter vos transferts ? Cela va supprimer le transfert qui est en cours.',
        transferInProgressToHomepage:
          "Vous souhaitez aller à la page d'acceuil ? Cela va supprimer le transfert qui est en cours.",
        deleteEmail: 'Êtes vous certains de vouloir supprimer le destinataire',
        phoneNumber: 'Numéro de téléphone',
        thePhoneNumber: 'le numéro de téléphone',
        modified: 'Modifier le',
        addOne: 'Ajouter un',
        phoneNumberFor: 'numéro de téléphone pour',
        required: 'Ce champs est requis',
        information:
          "Votre destinataire recevra un code par SMS qu'il devra renseigner pour dévérouiller son transfert.",
        deletePhoneNumber:
          'Êtes-vous certains de vouloir supprimer le numéro de téléphone',
        for: 'de',
      },
    },
    error: {
      phoneNumber: 'Numéro de téléphone non valide',
    },
  },
  generateLinkPage: {
    toast: {
      linkCopied: 'Lien copié dans le presse-papier',
      passwordCopied: 'Mot de passe copié',
    },
    modal: {
      cancel: "Êtes-vous certain de vouloir arrêter l'upload de",
      success: 'Transfert effectué avec succès !',
      error: "Une erreur s'est produite. Veuillez vérifier votre connexion",
      password: "N'oubliez pas de communiquer votre mot de passe",
      button: {
        next: 'Continuer',
        new: 'Faire un nouveau transfert',
        stop: "Arrêter l'upload",
        ok: 'OK',
      },
    },
  },
};

export default FR_FR;
