export class AbortHelper {
  private abortController = new AbortController();

  abort = (): void => {
    this.abortController.abort();
  };

  get signal(): AbortController['signal'] {
    return this.abortController.signal;
  }

  isAborted = (): boolean => this.abortController.signal.aborted;

  doIfNotAborted = (block: () => void): void => {
    if (!this.isAborted()) {
      block();
    }
  };

  doAsyncIfNotAborted = async (block: () => Promise<void>): Promise<void> => {
    if (!this.isAborted()) {
      block().then();
    }
  };
}

const newAbortHelper = (): AbortHelper => new AbortHelper();

export default newAbortHelper;
