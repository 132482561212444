import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../../contexts/UserContext';
import { Background, Modal, Button, Icon } from '@ekino/serenly-ui';
import { ekinoBackground, LogoEkino } from '../../assets/images';
import { ReactComponent as Logo } from '../../assets/images/send_logo.svg';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { RoutePath } from '../routes';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { PAGES } from '../../constants/tracking';

const LoginFooter = styled.div`
  position: absolute;
  bottom: 2%;
`;

const StyledInfo = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

const StyledSpan = styled.span`
  width: 20px;
  height: 20px;
`;

const LoginPage = ({ ...rest }) => {
  const { signIn } = useAuth();
  const { t } = useTranslation();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: PAGES.LOGIN,
    });
  }, [trackPageView]);

  return (
    <>
      <Background image={ekinoBackground} {...rest}>
        <Modal
          className="modal"
          open={true}
          closeIcon={false}
          children={
            <>
              <Modal.Icon>
                <Logo fill="black" />
              </Modal.Icon>
              <Modal.Item>
                {Object.values(
                  t('loginpageView.rules', { returnObjects: true })
                ).map(value => (
                  <StyledInfo key={value}>
                    <StyledSpan>
                      <Icon type="Check" sizeIcon="small" />
                    </StyledSpan>
                    <p>{value}</p>
                  </StyledInfo>
                ))}
              </Modal.Item>
            </>
          }
          actions={
            <Modal.Buttons direction="column">
              <Button
                variantButton="secondary"
                children={t('loginpageView.buttons.sso')}
                onClick={signIn}
              />
            </Modal.Buttons>
          }
          footer={
            <Modal.Footer>
              <LogoEkino width={150} />
            </Modal.Footer>
          }
        />

        <LoginFooter
          className="login-footer"
          style={{
            display: 'flex',
            gap: '15px',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <a
            href={RoutePath.LEGAL_NOTICES}
            title={t('loginpageView.links.legalMentions')}
            target="_blank"
            rel="noreferrer"
          >
            {t('loginpageView.links.legalMentions')}
          </a>
          <a
            href={RoutePath.TOS}
            title={t('loginpageView.links.tos')}
            target="_blank"
            rel="noreferrer"
          >
            {t('loginpageView.links.tos')}
          </a>
          <a
            href={RoutePath.PRIVACY_POLICY}
            title={t('loginpageView.links.privateCharte')}
            target="_blank"
            rel="noreferrer"
          >
            {t('loginpageView.links.privateCharte')}
          </a>
        </LoginFooter>
      </Background>
    </>
  );
};

export default styled(observer(LoginPage))`
  .modal {
    padding: 3rem 3.5rem 2rem;
    max-width: 380px;
  }
  .login-footer {
    a {
      font-size: 12px;
      color: white;
      text-decoration: none;
      z-index: 3000;
    }
  }
`;
