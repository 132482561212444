import { createWriteStream } from 'streamsaver';
import ApiClient, {
  AccessStatusUpdateBody,
  AccessUpdateModes,
  Exchange,
  ExchangeCreateRequest,
  ExchangeDocumentResponse,
  ExchangesGetRequest,
  ExchangeShareToken,
  ExchangesResponse,
  ExchangeStatus,
} from '../clients/ApiClient';
import EncryptionService, { ChunkContent } from './EncryptionService';
import { IAttempts } from './AuthenticationService';

class ExchangeService {
  private readonly authTokenProvider: () => string;

  constructor(authTokenProvider: () => string) {
    this.authTokenProvider = authTokenProvider;
  }

  createExchange = async (input: ExchangeCreateRequest): Promise<Exchange> => {
    const { data } = await ApiClient.createExchange(
      this.authTokenProvider(),
      input
    );
    return data;
  };

  getExchanges = async (
    params: ExchangesGetRequest = {}
  ): Promise<ExchangesResponse> => {
    const { data } = await ApiClient.getExchanges(
      this.authTokenProvider(),
      params
    );
    return data;
  };

  private formatExchange = (data: any = {}): Exchange => {
    return {
      id: data.id,
      name: data.name || '',
      status: data.status as ExchangeStatus,
      receivers:
        data.receivers && data.receivers.length > 0 ? data.receivers : [{}],
      sender: {
        email: (data.sender && data.sender.email) || '',
        full_name: (data.sender && data.sender.full_name) || '',
      },
      content_decryption_key: data.content_decryption_key,
      config: data.config,
      created_date: data.created_date,
    };
  };

  getExchange = async (exchangeId: string): Promise<Exchange> => {
    const { data } = await ApiClient.getExchange(
      this.authTokenProvider(),
      exchangeId
    );

    return this.formatExchange(data);
  };

  createDocument = async (
    exchangeId: string,
    fileMetadata: string
  ): Promise<ExchangeDocumentResponse> => {
    const { data } = await ApiClient.createDocument(
      this.authTokenProvider(),
      exchangeId,
      {
        metadata: fileMetadata,
      }
    );
    return data;
  };

  getDocuments = async (
    exchangeId: string
  ): Promise<ExchangeDocumentResponse[]> => {
    const { data } = await ApiClient.getDocuments(
      this.authTokenProvider(),
      exchangeId
    );
    return data;
  };

  downloadDocument = async (
    exchangeId: string,
    documentId: string,
    targetName: string,
    privateKeyArmored: string,
    secret: string
  ): Promise<any> => {
    const { data: chunkIndexes } = await ApiClient.getDocumentChunks(
      this.authTokenProvider(),
      exchangeId,
      documentId
    );
    const streamWriter = createWriteStream(targetName).getWriter();

    const chunkSorted = chunkIndexes.sort((a: number, b: number) => a - b);
    let chunksCollector: ArrayBuffer[] = [];

    const perChunk = 10; // items per chunk
    const chunkBatches = chunkSorted.reduce(
      (resultArray: number[][], item: number, index: number) => {
        const chunkIndex = Math.floor(index / perChunk);

        if (!resultArray[chunkIndex]) {
          // eslint-disable-next-line no-param-reassign
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      },
      []
    );

    for (let chunkBatch of chunkBatches) {
      const promises = chunkBatch.map(async (chunk: number) => {
        const data = await ApiClient.downloadDocumentChunk(
          this.authTokenProvider(),
          exchangeId,
          documentId,
          chunk
        ).then(response => response.data);
        return data as ArrayBuffer;
      });

      const chunkBatchData = await Promise.all(promises).then(
        (results: ArrayBuffer[]) => results
      );

      chunksCollector = chunksCollector.concat(chunkBatchData);
    }

    const chunksDecryptor: Promise<any>[] = await EncryptionService.decryptFile(
      privateKeyArmored,
      secret,
      chunksCollector
    );

    for (const chunkDecryptor of chunksDecryptor) {
      const reader: ReadableStreamReader<Uint8Array> | undefined = new Response(
        new Blob([await chunkDecryptor])
      ).body!!.getReader();

      const pump = async (): Promise<void> => {
        const { value, done } = await reader.read();

        if (done) {
          return;
        }

        await streamWriter.write(value);
        await pump();
      };

      await pump();
    }

    streamWriter.close();
  };

  uploadDocumentChunk = async (
    exchangeId: string,
    documentId: string,
    chunk: ChunkContent,
    index: number,
    abortController: AbortController
  ): Promise<void> => {
    await ApiClient.uploadDocumentChunk(
      this.authTokenProvider(),
      exchangeId,
      documentId,
      index,
      chunk,
      abortController
    );
  };

  setContentDecryptionKey = async (
    exchangeId: string,
    privateKey: string
  ): Promise<ExchangeDocumentResponse> => {
    const { data } = await ApiClient.setContentDecryptionKey(
      this.authTokenProvider(),
      exchangeId,
      {
        decryption_key: privateKey,
      }
    );
    return data;
  };

  private formatAccessToken = (access: any = {}): ExchangeShareToken => {
    return {
      id: access.id,
      identityVerificationRequired: access.identity_verification_required,
      enabled: access.enabled,
      receiver: {
        id: access.receiver && access.receiver.id,
        email: access.receiver && access.receiver.email,
        phone_number: access.receiver && access.receiver.phone_number,
      },
      stats: {
        identityVerificationAttempts: {
          failedAttemptsCount:
            (access.identity_verification_attempts &&
              access.identity_verification_attempts.failed_attempts_count) ||
            0,
          failedAttemptsLimit:
            (access.identity_verification_attempts &&
              access.identity_verification_attempts.failed_attempts_limit) ||
            -1,
        },
        contentUnlockAttempts: {
          failedAttemptsCount:
            (access.content_unlock_attempts &&
              access.content_unlock_attempts.failed_attempts_count) ||
            0,
          failedAttemptsLimit:
            (access.content_unlock_attempts &&
              access.content_unlock_attempts.failed_attempts_limit) ||
            -1,
        },
      },
    };
  };

  getExchangeTokens = async (
    exchangeId: string
  ): Promise<ExchangeShareToken[]> => {
    const { data } = await ApiClient.getShareTokens(
      this.authTokenProvider(),
      exchangeId
    );
    return data.accesses.map((access: any) => this.formatAccessToken(access));
  };

  archiveExchange = async (exchangeId: string): Promise<void> => {
    await ApiClient.archiveExchange(this.authTokenProvider(), exchangeId);
  };

  notifyDecryptAttempt = (
    idToken: string,
    status: { status: 'SUCCESS' | 'FAIL' }
  ): Promise<IAttempts> => {
    return ApiClient.eventUnlockAttempt(
      this.authTokenProvider(),
      idToken,
      status
    ).then(result => {
      return {
        failedAttemptsCount: result.data.failed_attempts_count,
        failedAttemptsLimit: result.data.failed_attempts_limit,
      };
    });
  };

  notifyDocumentDownload = (
    idToken: string,
    idDocument: string
  ): Promise<void> => {
    return ApiClient.eventDocumentRetrieved(
      this.authTokenProvider(),
      idToken,
      idDocument
    );
  };

  getAccessToken = (exchangeId: string, accessId: string): any => {
    return ApiClient.getAccessToken(
      this.authTokenProvider(),
      exchangeId,
      accessId
    ).then(result => {
      return {
        accessToken: result.data.access_token,
        expirationDate: result.data.expiration_date,
      };
    });
  };

  updateAccessActivationStatus = (
    exchangeId: string,
    accessId: string,
    mode: AccessUpdateModes,
    data: AccessStatusUpdateBody
  ): any => {
    return ApiClient.updateAccessActivationStatus(
      this.authTokenProvider(),
      exchangeId,
      accessId,
      mode,
      data
    ).then(result => {
      return {
        accessToken: result.data.access_token,
        expirationDate: result.data.expiration_date,
      };
    });
  };
}

export default ExchangeService;
