export const PAGES = {
  HOME: 'home',
  TRANSFER: 'transfer',
  UPLOAD: 'upload',
  UPLOAD_FORM: 'upload form',
  LOGIN: 'login',
  DOWNLOAD: 'download',
  TOS: 'terms of services',
  PRIVACY_POLICY: 'privacy policy',
  LEGAL_NOTICE: 'legal_notice',
};

export const CATEGORIES = {
  TRANSFER: 'transfer',
  TRANSFER_LINK: 'transfer link',
};

export const ACTIONS = {
  CREATE: 'create',
  CANCEL: 'cancel',
  COMPLETE: 'complete',
  ARCHIVE: 'archive',
  REACTIVATE: 'reactivate',
  DEACTIVATE: 'deactivate',
};

export const DIMENSIONS = {
  MAIL: 'mail',
  LINK: 'link',
  PROTECTED_BY_SMS: 'protected_by_sms',
  NOT_PROTECTED_BY_SMS: 'not_protected_by_sms',
};
