import React, { useEffect, useState, useCallback } from 'react';
import { useLocalObservable } from 'mobx-react-lite';
import { reaction } from 'mobx';
import { createUserStore, storageKeys, TUserStore } from '../stores/UserStore';
import useExchangeService from '../hooks/exchangeService';
import ExchangeService from '../services/ExchangeService';

import { Amplify, Auth, Hub } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import Environment from '../config/Environment';
import AuthenticationService from '../services/AuthenticationService';
import Loader from '../components/shared/Loader';

export interface IAuth {
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
}
export interface UserStoreContextProps extends IAuth {
  store: TUserStore;
}

const userStoreContext = React.createContext({} as UserStoreContextProps);

export interface UserStoreProviderProps {
  children?: React.ReactNode;
}

export const UserStoreProvider = ({
  children,
}: UserStoreProviderProps): JSX.Element => {
  const store = useLocalObservable(createUserStore);
  const [authenticating, setAuthenticating] = useState(true);

  const saveUser = useCallback(
    (cognitoUser: CognitoUser) => {
      const userSession = cognitoUser.getSignInUserSession();
      const idToken = userSession?.getIdToken();
      const name = `${idToken?.payload?.given_name} ${idToken?.payload?.family_name}`;
      store.setCognitoAuth(idToken?.getJwtToken() || '', name, undefined);
    },
    [store]
  );

  const checkUser = useCallback(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();

      if (cognitoUser) {
        saveUser(cognitoUser);
      }
    } catch (error) {
      console.error(error);
      setAuthenticating(false);
    }
  }, [saveUser]);

  const signIn = async () => {
    await Auth.federatedSignIn();
  };

  const signOut = useCallback(async () => {
    store.clearAuthentication();
  }, [store]);

  // auth config + listener + check
  useEffect(() => {
    Amplify.configure({
      Auth: {
        region: Environment.cognito.region,
        userPoolId: Environment.cognito.userPoolId,
        userPoolWebClientId: Environment.cognito.clientId,
        mandatorySignIn: false,
        redirectSignIn: Environment.cognito.redirectUrl,
        redirectSignOut: Environment.cognito.redirectUrl,
      },
    });
    Auth.configure({
      oauth: {
        domain: Environment.cognito.domain,
        scope: ['openid', 'email', 'profile'],
        redirectSignIn: Environment.cognito.redirectUrl,
        redirectSignOut: Environment.cognito.redirectUrl,
        responseType: 'code',
      },
    });
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn': {
          saveUser(data);
          break;
        }
        case 'signOut': {
          signOut();
          break;
        }
      }
    });

    checkUser();
  }, [checkUser, saveUser, store, signOut]);

  // localStorage googleAuth
  useEffect(
    () =>
      reaction(
        () => store.currentUser.googleAuth,
        item => storageKeys.googleAuth.save(item)
      ),
    [store]
  );

  // localStorage appAuth
  useEffect(
    () =>
      reaction(
        () => store.currentUser.appAuth,
        item => storageKeys.appAuth.save(item)
      ),
    [store]
  );

  // localStorage user profile
  useEffect(
    () =>
      reaction(
        () => store.currentUser.profile,
        item => storageKeys.profile.save(item)
      ),
    [store]
  );

  //localStorage user preferences
  useEffect(
    () =>
      reaction(
        () => store.currentUser.preferences.navOpened,
        () => storageKeys.preferences.save(store.currentUser.preferences)
      ),
    [store]
  );

  // authenticate with cognitoAuth
  useEffect(
    () =>
      reaction(
        () => store.currentUser.cognitoAuth,
        async cognitoAuth => {
          if (cognitoAuth) {
            const appAuth =
              await AuthenticationService.authenticateInternalUser(
                cognitoAuth.idToken
              );

            store.setAppAuth(appAuth);
            setAuthenticating(false);
          }
        }
      ),
    [store]
  );

  // authenticate with googleAuth
  useEffect(
    () =>
      reaction(
        () => store.currentUser?.googleAuth,
        async googleToken => {
          if (googleToken) {
            const appAuth =
              await AuthenticationService.authenticateInternalUser(
                googleToken.idToken
              );
            store.setAppAuth(appAuth);
          }
        }
      ),
    [store]
  );

  // get user profile
  useEffect(() => {
    const disposer = reaction(
      () => store.currentUser.appAuth,
      async appAuth => {
        if (appAuth) {
          const profile = await AuthenticationService.getUserProfile(
            appAuth.accessToken
          );

          store.setProfile(profile);
        }
      }
    );

    return disposer;
  }, [store]);

  return (
    <userStoreContext.Provider value={{ store, signIn, signOut }}>
      {authenticating ? <Loader>Authenticating...</Loader> : children}
    </userStoreContext.Provider>
  );
};

export const useAuth = (): IAuth => {
  const { signIn, signOut } = React.useContext(userStoreContext);

  if (!signIn || !signOut) {
    throw new Error('useAuth must be used within a userStoreProvider.');
  }

  return { signIn, signOut };
};

export const useUserStore = (): TUserStore => {
  const { store } = React.useContext(userStoreContext);

  if (!store) {
    throw new Error('useUserStore must be used within a userStoreProvider.');
  }

  return store;
};

export const useUserExchangeService = (): ExchangeService => {
  const userStore = useUserStore();
  const [exchangeService] = useExchangeService(userStore.getAuthTokenOrFail());
  return exchangeService;
};
