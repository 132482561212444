import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Exchange,
  ExchangeDocumentResponse,
  ExchangeReceiverAccess,
} from '../../clients/ApiClient';
import useExchangeService from '../../hooks/exchangeService';
import DownloadDocumentsDisplay from './DownloadDocumentsDisplay';

interface DownloadShareDetailsProps {
  authentication: ExchangeReceiverAccess;
  smsMode: boolean;
}

function DownloadShareDetails({
  authentication: {
    exchange_id: exchangeId,
    token: { access_token: accessToken },
  },
  smsMode,
}: DownloadShareDetailsProps) {
  const { t } = useTranslation();
  const [exchangeService] = useExchangeService(accessToken);
  const [exchange, setExchange] = useState<Exchange | undefined>(undefined);
  const [documentsResponse, setDocumentsResponse] = useState<
    ExchangeDocumentResponse[] | undefined
  >(undefined);

  useEffect(() => {
    const getDownload = async () => {
      try {
        const getExchange = await exchangeService.getExchange(exchangeId);
        const getDocuments = await exchangeService.getDocuments(exchangeId);
        setExchange(getExchange);
        setDocumentsResponse(getDocuments);
      } catch (e) {
        console.error(t('exchangeDetails.error.unableToGetExchange'), e);
        console.error(t('exchangeDetails.error.unableToGetDocument'), e);
      }
    };
    getDownload();
  }, [exchangeId, exchangeService, t]);

  return (
    <>
      {exchange && (
        <>
          {documentsResponse && exchange.content_decryption_key && (
            <DownloadDocumentsDisplay
              sender={exchange.sender}
              sendDate={exchange.created_date}
              expirationDate={exchange.config?.download_expiration}
              documentsCount={documentsResponse?.length}
              displayTos={!smsMode}
              smsMode={smsMode}
              accessToken={accessToken}
              exchangeId={exchange.id}
              encodedDecryptionKey={exchange.content_decryption_key}
              documentsResponse={documentsResponse}
              exchangeManager={exchangeService}
              autoFocusOnPassword
            />
          )}
        </>
      )}
    </>
  );
}

export default DownloadShareDetails;
