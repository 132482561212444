import { useEffect } from 'react';
import DisclaimerLayout from '../layout/DisclaimerLayout';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { PAGES } from '../../constants/tracking';

const PrivacyPolicy = ({ ...rest }) => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: PAGES.PRIVACY_POLICY,
    });
  }, [trackPageView]);

  return (
    <DisclaimerLayout {...rest}>
      <header>
        <h1>Politique de confidentialité de la plateforme send.ekino.com</h1>
        <p>Mise à jour : 15 mars 2021</p>
      </header>

      <p>
        Votre vie privée est importante pour Nous et Nous (« EKINO ») y
        accordons une attention toute particulière. Nous respectons la
        confidentialité et nous engageons à protéger vos Données à caractère
        personnel recueillis à des fins exclusives du service proposé via notre
        Plateforme SEND.EKINO.COM (ci-après la « Plateforme »).
      </p>

      <p>
        Cette politique de confidentialité a pour objectif de Vous informer de
        la manière dont nous traitons Vos Données à caractère personnel et nous
        engageons à les traiter dans le respect de la réglementation applicable
        en matière de protection de Données à caractère personnel.
      </p>

      <p>
        Tous les termes non définis aux présentes ont la même signification que
        celle qui leur est donnée à l'article 4 du Règlement UE 2016/679 du 27
        avril 2016 (ou « RGPD ») ou dans les{' '}
        <a href="/">Conditions Générales d'Utilisation</a>.
      </p>

      <section>
        <h2>
          1. Quelle est notre rôle en matière de traitement de données à
          caractère personnel ?
        </h2>
        <p>
          Le Responsable du traitement de vos Données à caractère personnel dans
          le cadre de la présente Politique est EKINO (ou « Nous »). Pour plus
          de précision sur les <a href="/">Informations Légales, cliquer ici</a>
          .
        </p>
        <p>
          EKINO exploite la Plateforme dénommée SEND.EKINO.COM. Cette Plateforme
          a vocation à permettre l'envoi sécurisé, confidentiel et tracé de
          documents (ci-après « Contenus ») à <strong>l'Utilisateur</strong> de
          la Plateforme (ou « <strong>Vous</strong> », « <strong>Votre</strong>{' '}
          »). L'Expéditeur est un collaborateur EKINO ; le Destinataire, tout
          personne externe ; tous deux Utilisateurs.
        </p>
        <p>
          Dans ce cadre, EKINO s'engage à respecter les principes définis par
          les dispositions légales et réglementaires en matière de protection
          des Données à caractère personnel, en particulier dans le Règlement
          (UE) 2016/679 du 27 avril 2016 relatif à la protection des personnes
          physiques à l'égard du Traitement des Données à caractère personnel («
          RGPD »), et met en œuvre des procédures internes de gestion, de
          conservation et de sécurisation de vos Données à caractère personnel,
          que Vous, Utilisateur, soyez candidat, salarié, freelance, client,
          prospect, contact, internaute, fournisseur, prestataire ou
          Sous-traitant.
        </p>
        <p>
          Il est précisé que la présente Politique concerne exclusivement les
          Traitements de Données à caractère personnel mis en œuvre par la
          société en qualité de Responsable de traitement et non en qualité de
          Sous-traitant.
        </p>
      </section>
      <section>
        <h2>
          2. Quelles sont les modalités de collecte de vos données à caractere
          personnel ?
        </h2>
        <p>
          <dl>
            <dt>Pour l'Expéditeur :</dt>
            <dd>
              <strong>Collecte directe</strong> : vos Données à caractère
              personnel sont collectées directement auprès de Vous lors de votre
              saisie de demande d'envoi de documents chiffrés dans le cadre de
              votre mission pour EKINO.
            </dd>

            <dt>Pour le Destinataire :</dt>
            <dd>
              <strong>Collecte directe</strong> : vos Données à caractère
              personnel sont collectées directement auprès de Vous. En effet,
              vos Données à caractère personnel sont notamment collectées ou
              traitées en tout ou partie à l'occasion de votre navigation sur le
              site ekino.com et de la saisie par vos soins d'informations dans
              les formulaires de collecte de données y figurant, mais également
              plus généralement dans le cadre de demandes que vous pouvez être
              amenés à adresser à EKINO par tout moyen à votre convenance, de
              votre relation contractuelle ou précontractuelle et de vos
              échanges avec EKINO, lorsque vous vous connectez à un réseau
              social tiers sur l'une des pages de EKINO sur les réseaux sociaux.
            </dd>
            <dd>
              <strong>Collecte indirecte</strong> : vos Données à caractère
              personnel peuvent également être collectées de manière indirecte
              par l'intermédiaire de tiers (par exemple, dans le cadre d'un
              recrutement : agences ou cabinets de recrutement, précédents
              employeurs, réseaux sociaux professionnels).
            </dd>
          </dl>
        </p>
      </section>

      <section>
        <h2>
          3. Quelles sont les données à caractère personnel que nous traitons ?
          Pour quelles finalités ? Sur quelles bases légales ?
        </h2>
        <table>
          <p>
            Vous trouverez ci-dessous la liste des Données à caractère personnel
            que Nous utilisons, la finalité et la base légale utilisée pour
            chaque Traitement effectué :
          </p>
        </table>
        <thead>
          <tr>
            <th>
              <p>Catégorie de personnes concernées</p>
            </th>
            <th>
              <p>Type de données à caractère personnelles</p>
            </th>
            <th>
              <p>Finalité du traitement</p>
            </th>
            <th>
              <p>Base légale du traitement</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              <p>Destinataire du Contenu</p>
            </th>
            <td>
              <p>
                <ul>
                  <li>Email</li>
                  <li>Téléphone</li>
                </ul>
              </p>
            </td>
            <td>
              <p>
                Envoyer de manière sécurisée, confidentielle et traçable un
                Contenu via la Plateforme
              </p>
            </td>
            <td>
              <p>Exécution d'un contrat ou de mesure pré-contractuelle</p>
            </td>
          </tr>
          <tr>
            <th rowSpan={2} scope="row">
              <p>Expéditeur / Destinataire du Contenu</p>
            </th>
            <td>
              <p>
                Données de trafic ou de connexion (comprenant, les informations
                permettant d'identifier l'utilisateur telle Votre adresse IP ;
                des caractéristiques techniques ainsi que la date, l'horaire et
                la durée de chaque communication ; les données permettant
                d'identifier le ou les destinataires de la communication).
              </p>
            </td>
            <td>
              <p>
                S'assurer la sécurité, de la preuve et de la traçabilité de la
                communication
              </p>
            </td>
            <td>
              <p>
                Exécution d'une obligation légale en tant que fournisseur de
                service de communication
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <ul>
                  <li>Nom</li>
                  <li>Prénom</li>
                </ul>
              </p>
            </td>
            <td>
              <p>
                Gérer les demandes d'exercice de droits des Personnes Concernées
              </p>
            </td>
            <td>
              <p>
                Exécution d'une obligation légale (au titre du RGPD et Loi
                Informatique et Libertés)
              </p>
            </td>
          </tr>
        </tbody>
        <p>Nous ne traitons jamais le Contenu du transfert.</p>
      </section>

      <section>
        <h2>
          4. Qui sont les destinataires de vos données à caractère personnel ?
          Comment partageons-nous vos données ?
        </h2>
        <p>
          Nous veillons à ce que seules des personnes habilitées au sein de
          EKINO puissent accéder à vos Données à caractère personnel, à savoir
          les membres de l'Équipe SMSI/Sécurité.
        </p>
        <p>
          Il est précisé qu'Amazon assure le stockage des données et des
          fichiers à travers son service AMAZON WEB SERVICES et les serveurs
          sont localisés au sein de l'EEE ; néanmoins, ceux-ci sont chiffrés,
          sans possibilité de déchiffrement.
        </p>
      </section>

      <section>
        <h2>
          5. Pour quelle durée conservons-nous vos données à caractère personnel
          ?
        </h2>
        <p>
          Nous appliquons les durées de conservation suivantes :
          <ul>
            <li>
              Pour l'envoi sécurisé du Contenu / conservation du contenu :
              conservation pendant un (7) jour en base active puis supprimé ;
            </li>
            <li>
              Pour le respect de l'obligation de sécurité post envoi du Contenu
              / conservation des données de trafic ou de connexion à des fins de
              preuve et de traçabilité : un (1) an à compter de l'envoi du
              Contenu ;
            </li>
            <li>
              Pour la gestion des demandes d'exercice des droits des Personnes
              Concernées : le temps nécessaire au traitement de la demande
              augmenté des prescriptions légales.
            </li>
          </ul>
        </p>
      </section>
      <section>
        <h2>6. Comment sont sécurisées vos données à caractère personnel ?</h2>
        <p>
          EKINO met en place des mesures de sécurité organisationnelles et
          techniques adaptées , au regard notamment des catégories de Données à
          caractère personnel traitées, de l'état des connaissances, des coûts
          de mise en œuvre et de la nature, de la portée, du conpe et des
          finalités des Traitements ainsi que des risques, dont le degré de
          probabilité et de gravité varie, pour les droits et libertés des
          personnes physiques, pour protéger Vos Données à caractère personnel
          contre toute intrusion malveillante, toute perte, altération ou
          divulgation à des tiers non autorisés, et plus généralement pour
          préserver la sécurité et la confidentialité desdites Données à
          caractère personnel et garantir un niveau de sécurité adapté au
          risque.
        </p>
        <p>
          En raison des difficultés inhérentes à l'exercice d'une activité sur
          internet et des risques, que Vous connaissez, résultant d'une
          transmission de données par voie électronique, EKINO ne saurait être
          tenue à une obligation de résultat.
        </p>
        <p>
          En cas de survenance de difficultés, EKINO fera ses meilleurs efforts
          pour circonscrire les risques et prendra toutes les mesures adéquates,
          conformément à ses obligations légales et réglementaires (actions
          correctrices, information de la Cnil et le cas échéant des personnes
          concernées, …).
        </p>
        <p>
          Lors de l'élaboration et de la conception de la Plateforme, EKINO
          veillait à la bonne prise en compte des principes et obligations
          édictés par le RGPD et plus particulièrement, (i) les principes de
          privacy by design et privacy by default ainsi que (ii) son obligation
          de sécurité par la mise en place de moyens de cryptographie pour
          l'envoi sécurisé, confidentiel et tracé de ses Contenus, notamment par
          le chiffrement.
        </p>
        <p>
          EKINO impose contractuellement à ce que l'ensemble des salariés
          habilités ayant accès à vos Données à caractère personnel soient liés
          par une obligation de confidentialité et s'exposent à des sanctions en
          cas de non-respect.
        </p>
        <p>
          En cas de sous-traitance une partie ou de la totalité d'un Traitement
          de Données à caractère personnel, EKINO impose contractuellement à ses
          Sous-traitants des garanties de sécurité et notamment de
          confidentialité s'agissant des Données à caractère personnel auxquels
          ils peuvent avoir accès (mesures techniques et organisationnelles
          appropriées de protection de ces Données).
        </p>
      </section>
      <section>
        <h2>
          7. Vos données à caractère personnel sont-elles transférées hors Union
          Européenne ?
        </h2>
        <h2>
          Vos Données à caractère personnel sont traitées au sein de l’Union
          européenne.
        </h2>
      </section>
      <section>
        <p />
        <p />
      </section>
      <section>
        <h2>8. Quels sont vos droits et comment les exercer ?</h2>
        <p>
          Vous bénéficiez, selon les conditions et modalités et dans les limites
          définies par les dispositions applicables en matière de protection des
          Données à caractère personnel, des droits suivants :
          <ul>
            <li>
              <strong>Droit d’accès</strong> : vous pouvez obtenir la
              confirmation que des Données à caractère personnel vous concernant
              sont traitées ou non par la société et, lorsqu’elles le sont,
              l’accès auxdites Données à caractère personnel, ainsi que
              certaines informations relatives aux Traitements de vos Données à
              caractère personnel ;
            </li>
            <li>
              <strong>Droit de rectification</strong> : vous pouvez solliciter
              la correction de vos Données à caractère personnel que vous
              estimez incomplètes ou inexactes ;
            </li>
            <li>
              <strong>Droit à l’effacement</strong> : vous pouvez dans certaines
              hypothèses solliciter l’effacement de vos Données à caractère
              personnel ;
            </li>
            <li>
              <strong>Droit à la limitation du Traitement</strong> : vous pouvez
              demander la limitation du Traitement de vos Données à caractère
              personnel, vous permettant de demander dans certaines hypothèses
              le marquage de vos Données à caractère personnel afin d’en limiter
              le Traitement futur ;
            </li>
            <li>
              <strong>
                Droit à la portabilité de vos Données à caractère personnel
              </strong>{' '}
              : vous avez le droit dans certaines hypothèses et sous certaines
              conditions de demander à recevoir les Données à caractère
              personnel vous concernant que vous nous avez fournies ou, lorsque
              cela est possible techniquement, à ce qu’elles soient transférées
              à un tiers, dans une forme lisible par machine ;
            </li>
            <li>
              <strong>Droit de retirer votre consentement</strong> si le
              Traitement est opéré sur la base légale de votre consentement ;
            </li>
            <li>
              <strong>
                Droit de saisir la Commission Nationale de l’Informatique et des
                Libertés
              </strong>{' '}
              ou « Cnil » de toute éventuelle réclamation si vous estimez que le
              Traitement de vos Données à caractère personnel n’est pas effectué
              conformément aux dispositions applicables en matière de protection
              des Données à caractère personnel ;
            </li>
            <li>
              <strong>
                Droit de définir des directives relatives à la conservation, à
                l’effacement ou à la communication de vos Données à caractère
                personnel après votre décès
              </strong>{' '}
              : à cet égard, en cas de décès qui serait porté à notre
              connaissance, sachez que vos Données à caractère personnel seront
              supprimées, sauf nécessité de conservation pendant une durée
              déterminée pour des motifs tenant à nos obligations légales et
              réglementaires et/ou aux délais légaux de prescription visés
              supra, après le cas échéant avoir été communiquées à un tiers
              éventuellement désigné par vos soins.
            </li>
          </ul>
        </p>
        <p>
          Vous pouvez exercer vos droits auprès de notre délégué à la protection
          des données :
          <ul>
            <li>par email : dpo@ekino.com ;</li>
            <li>
              par courrier postal à l’adresse suivante : EKINO – Délégué à la
              protection des données - 157 rue Anatole France - 92300
              Levallois-Perret.
            </li>
          </ul>
        </p>
        <p>
          En toutes hypothèses, en cas de doute raisonnable quant à l'identité
          de la personne présentant une telle demande d’exercice de ses droits,
          EKINO pourra toujours demander que lui soient fournies des
          informations supplémentaires nécessaires pour confirmer l'identité de
          la Personne concernée et demander à cette fin, lorsque la situation
          l'exige, la photocopie d'un titre d'identité portant la signature du
          titulaire.
        </p>
        <p>
          Nous vous répondrons dans les meilleurs délais et en tout état de
          cause dans un délai d’un (1) mois à compter de la réception de Votre
          demande. Au besoin, nous pourrons prolonger ce délai de deux (2) mois,
          compte tenu de la complexité et du nombre de demandes, et nous vous en
          informerons spécifiquement.
        </p>
      </section>
      <section>
        <h2>9. Cookies</h2>
        <p>
          Aucun cookie et autres traceurs ou technologies similaires ne peut
          être installé et/ou lu dans votre navigateur lors de l’Utilisation de
          la Plateforme
        </p>
      </section>
      <section>
        <h2>10. Modification de la présente politique de confidentialité</h2>
        <p>
          La présente Politique de Confidentialité peut être modifiée. Pour
          cette raison, Nous Vous invitons à la consulter régulièrement.
        </p>
      </section>
    </DisclaimerLayout>
  );
};

export default PrivacyPolicy;
