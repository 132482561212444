import { StorageEntry, clearStorage } from '../helpers/StorageHelper';

export type TProviderAuth = {
  idToken: string;
  name: string;
  imageUrl?: string;
};

export type TAppAuth = {
  accessToken: string;
  expirationDate: string;
};

export enum TUserType {
  INTERNAL_USER = 'INTERNAL_USER',
  EXCHANGE_RECEIVER = 'EXCHANGE_RECEIVER',
}

export enum UserRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export type TUserProfile = {
  id: string;
  type: TUserType;
  role: UserRole;
};

export type TUserPreferences = {
  navOpened: boolean;
};

export type TUser = {
  googleAuth?: TProviderAuth;
  cognitoAuth?: TProviderAuth;
  appAuth?: TAppAuth;
  profile?: TUserProfile;
  preferences: TUserPreferences;
};

export const storageKeys = {
  googleAuth: new StorageEntry<TProviderAuth>('googleAuth'),
  cognitoAuth: new StorageEntry<TProviderAuth>('cognitoAuth'),
  appAuth: new StorageEntry<TAppAuth>('appAuth'),
  profile: new StorageEntry<TUserProfile>('profile'),
  preferences: new StorageEntry<TUserPreferences>('preferences'),
};

const createDefaultUser = (): TUser => ({
  googleAuth: storageKeys.googleAuth.load(),
  cognitoAuth: storageKeys.cognitoAuth.load(),
  appAuth: storageKeys.appAuth.load(),
  profile: storageKeys.profile.load(),
  preferences: storageKeys.preferences.load() ?? {
    navOpened: true,
  },
});

interface ICreateUserStore {
  currentUser: TUser;
  isAuthenticated(): boolean;
  clearAuthentication(): void;
  setGoogleAuth(idToken: string, name: string, imageUrl?: string): void;
  setCognitoAuth(idToken: string, name: string, imageUrl?: string): void;
  setAppAuth(appAuth: TAppAuth): void;
  setProfile(profile: TUserProfile): void;
  getAuthTokenOrFail(): string;
}

export const createUserStore = (): ICreateUserStore => {
  return {
    currentUser: createDefaultUser(),
    isAuthenticated() {
      const authenticationExpirationDate =
        this.currentUser.appAuth?.expirationDate;
      if (authenticationExpirationDate) {
        return (
          new Date().getTime() <
          new Date(authenticationExpirationDate).getTime()
        );
      }
      return false;
    },
    clearAuthentication() {
      this.currentUser = {
        ...this.currentUser,
        googleAuth: undefined,
        cognitoAuth: undefined,
        appAuth: undefined,
        profile: undefined,
      };
      clearStorage();
    },
    setGoogleAuth(idToken, name, imageUrl) {
      this.currentUser.googleAuth = {
        idToken,
        name,
        imageUrl,
      };
    },
    setCognitoAuth(idToken, name, imageUrl) {
      this.currentUser.cognitoAuth = {
        idToken,
        name,
        imageUrl,
      };
    },
    setAppAuth(appAuth) {
      this.currentUser.appAuth = appAuth;
    },
    setProfile(profile) {
      this.currentUser.profile = profile;
    },
    getAuthTokenOrFail(): string {
      const accessToken = this.currentUser?.appAuth?.accessToken;
      if (accessToken) {
        return accessToken;
      }
      throw new Error('No authentication token');
    },
  };
};

export type TUserStore = ReturnType<typeof createUserStore>;
