import { useState } from 'react';
import { ExchangeFilesUploadProps } from '../components/sender/ExchangeFileUpload';
import DocumentService from '../services/DocumentService';
import { ChunkProcessor } from '../services/EncryptionService';
import { percent } from '../helpers/MathHelpers';

export interface IUploadFile
  extends Omit<
    ExchangeFilesUploadProps,
    | 'filesSize'
    | 'transferName'
    | 'setModalCancelUpload'
    | 'secret'
    | 'contentDecryptionKeyHandler'
  > {
  file: File;
  publicKey: string;
}

export type TUpload = {
  file: File;
  progress: number;
  status: number;
};

export enum UploadProgress {
  WAITING,
  IN_PROGRESS,
  DONE,
  ERROR,
}

export default function useUploadFile() {
  const [upload, setUpload] = useState<TUpload>();

  const uploadFile = async ({
    file,
    documentCreateHandler,
    documentChunkCreateHandler,
    publicKey,
  }: IUploadFile) => {
    try {
      let uploadedChunks = 0;
      const fileMetadata = await DocumentService.encryptFileMetadata(
        file,
        publicKey
      );

      setUpload({
        file,
        progress: 0,
        status: UploadProgress.WAITING,
      });

      const documentId = await documentCreateHandler(fileMetadata);

      const chunkProcessor: ChunkProcessor = async (
        chunk,
        index,
        totalCount
      ) => {
        try {
          await documentChunkCreateHandler(documentId, chunk, index);
          uploadedChunks++;
          setUpload({
            file,
            progress: percent(uploadedChunks, totalCount),
            status: UploadProgress.IN_PROGRESS,
          });
        } catch (error) {
          setUpload({
            file,
            progress: percent(uploadedChunks, totalCount),
            status: UploadProgress.ERROR,
          });
        }
      };

      await DocumentService.encryptDocument(file, publicKey, chunkProcessor);

      setUpload({
        file,
        progress: 100,
        status: UploadProgress.DONE,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    uploadFile,
    upload,
  };
}
