import { useUserStore } from './contexts/UserContext';
import { observer } from 'mobx-react-lite';
import AuthenticatedApp from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import { ToastContainer, Slide } from 'react-toastify';

export default observer(function App() {
  const userStore = useUserStore();

  return (
    <div className="App">
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        transition={Slide}
        closeButton={false}
      />
      {userStore.isAuthenticated() ? (
        <AuthenticatedApp />
      ) : (
        <UnauthenticatedApp />
      )}
    </div>
  );
});
