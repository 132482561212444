import { useState, useEffect, useCallback } from 'react';
import { Accordeon, Icon } from '@ekino/serenly-ui';
import styled from 'styled-components';
import {
  ExchangeStatus,
  ExchangeShareToken,
  AccessUpdateModes,
} from '../../clients/ApiClient';
import { useUserExchangeService } from '../../contexts/UserContext';
import { RoutePath } from '../routes';
import { useCopyToClipboard } from '../../hooks/copyToClipboard';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { CATEGORIES, ACTIONS } from '../../constants/tracking';

const TransferDetails = ({
  exchange,
  setModalArchiveTransfer,
  setExchangeToDelete,
}: any) => {
  const { t } = useTranslation();
  const exchangeService = useUserExchangeService();
  const [, copyToClipboard] = useCopyToClipboard();
  const { trackEvent } = useMatomo();
  const [accesses, setAccesses] = useState<ExchangeShareToken[] | undefined>([
    {
      id: '',
      identityVerificationRequired: false,
      enabled: true,
      receiver: {
        id: undefined,
        email: '',
        phone_number: undefined,
      },
      stats: {
        identityVerificationAttempts: {
          failedAttemptsCount: 0,
          failedAttemptsLimit: -1,
        },
        contentUnlockAttempts: {
          failedAttemptsCount: 0,
          failedAttemptsLimit: -1,
        },
      },
    },
  ]);

  const notifyCopyLink = () => {
    const link = t('generateLinkPage.toast.linkCopied');
    return toast.success(link, {
      icon: () => <Icon sizeIcon="medium" type="Link" />,
    });
  };

  const getShareTokens = useCallback(async () => {
    if (exchange?.status === ExchangeStatus.DOWNLOADABLE) {
      try {
        setAccesses(await exchangeService.getExchangeTokens(exchange.id));
      } catch (e) {
        console.error(t('shareToken.error'));
      }
    }
  }, [exchangeService, exchange, t]);

  useEffect(() => {
    getShareTokens();
  }, [getShareTokens]);

  const updateActivationStatus = (access: ExchangeShareToken) => {
    trackEvent({
      category: CATEGORIES.TRANSFER_LINK,
      action: access.enabled ? ACTIONS.DEACTIVATE : ACTIONS.REACTIVATE,
    });

    exchangeService
      .updateAccessActivationStatus(
        exchange.id,
        access.id,
        access.enabled ? AccessUpdateModes.DISABLE : AccessUpdateModes.ENABLE,
        { reason: '' }
      )
      .then(() => {
        getShareTokens();
      });
  };

  const onRegenerateLink = (access: any) => {
    trackEvent({
      category: CATEGORIES.TRANSFER_LINK,
      action: ACTIONS.REACTIVATE,
    });

    exchangeService
      .getAccessToken(exchange.id, access.id)
      .then((token: any) => {
        copyToClipboard(
          `${window.location.origin}${RoutePath.DOWNLOAD_PATH}/${token.accessToken}`
        );
      });
  };

  return (
    <Accordeon
      key={exchange.id}
      receivers={exchange.receivers}
      exchange={exchange}
      exchangesAccess={accesses}
      onDesactivate={(access: ExchangeShareToken) =>
        updateActivationStatus(access)
      }
      onRegenerate={(access: ExchangeShareToken) => {
        onRegenerateLink(access);
        notifyCopyLink();
      }}
      onArchiveTransfer={() => {
        trackEvent({
          category: CATEGORIES.TRANSFER,
          action: ACTIONS.ARCHIVE,
        });
        setModalArchiveTransfer(true);
        setExchangeToDelete(exchange);
      }}
    />
  );
};

export default styled(TransferDetails)``;
