import { ExchangeReceiverAccess } from '../clients/ApiClient';
import { IAttempts } from '../services/AuthenticationService';

interface ShareAuthenticationState {
  isLoading: boolean;
  error?: string;
  verificationRequired: boolean;
  initSms: boolean;
  access?: ExchangeReceiverAccess;
  attempts?: IAttempts;
}

type ShareAuthenticationAction =
  | { type: 'loading' }
  | { type: 'verification_required'; attempts: IAttempts }
  | { type: 'initialize_sms' }
  | { type: 'success'; access: ShareAuthenticationState['access'] }
  | { type: 'failure'; error?: string; attempts?: IAttempts };

const shareAuthenticationReducer = (
  state: ShareAuthenticationState,
  action: ShareAuthenticationAction
): ShareAuthenticationState => {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case 'initialize_sms':
      return {
        ...state,
        initSms: true,
      };
    case 'verification_required':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        verificationRequired: true,
        attempts: action.attempts,
      };
    case 'success':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        verificationRequired: false,
        access: action.access,
      };
    case 'failure':
      return {
        ...state,
        isLoading: false,
        error: action.error,
        attempts: action.attempts,
      };
    default:
      throw Error();
  }
};

export default shareAuthenticationReducer;
