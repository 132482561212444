import { Button, Card, CardLink, Icon, Input, styles } from '@ekino/serenly-ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ExchangeSender } from '../../clients/ApiClient';
import useExchangeService from '../../hooks/exchangeService';
import { RoutePath } from '../routes/path';
import EncryptionService from '../../services/EncryptionService';
import DownloadPreviewHeader from './DownloadPreviewHeader';
import DocumentError from './DocumentError';

const { convertPixelToRem } = styles;

interface FormInputs {
  password: string;
}

interface DecryptionKeyUnlockProps {
  sender: ExchangeSender;
  documentsCount?: number;
  displayTos?: boolean;
  accessToken: string;
  privateKeyArmored: string;
  onValidPassword: (password: FormInputs['password']) => void;
  autoFocus?: boolean;
  exchangeId: string;
  smsMode: boolean;
}

function DecryptionKeyUnlock({
  sender,
  documentsCount,
  privateKeyArmored,
  accessToken,
  onValidPassword,
  autoFocus,
  exchangeId,
  displayTos = false,
  smsMode,
  ...rest
}: DecryptionKeyUnlockProps) {
  const { t } = useTranslation();
  const [exchangeService] = useExchangeService(accessToken);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [attemptRemaining, setAttemptRemaining] = useState<number>(1);
  const [conditionsAccepted, setConditionsAccepted] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm<{
    password: string;
  }>({ defaultValues: { password: '' } });

  const onSubmit = async ({ password }: FormInputs) => {
    const success = await EncryptionService.hasValidPrivateKeyPassword(
      privateKeyArmored,
      password
    );

    let attempt;
    if (accessToken) {
      // We check only for external users hence with ExchangeReceiverAccess
      attempt = await exchangeService.notifyDecryptAttempt(exchangeId, {
        status: success ? 'SUCCESS' : 'FAIL',
      });
    }

    if (success) {
      setErrorMessage(undefined);
      onValidPassword(password);
    } else {
      let error = t('exchangeFiles.error.invalidPassword');

      if (attempt) {
        const remainingAttempt =
          attempt.failedAttemptsLimit - attempt.failedAttemptsCount;
        error = t('exchangeFiles.error.attemptRemaining', {
          count: remainingAttempt,
        });
        setAttemptRemaining(remainingAttempt);
      }
      setErrorMessage(error);
    }
  };

  const handleToggle = () => {
    setConditionsAccepted(!conditionsAccepted);
  };

  const isDisabled =
    displayTos && (!conditionsAccepted || isSubmitting || !isDirty);

  return (
    <div {...rest}>
      {attemptRemaining > 0 ? (
        <>
          <DownloadPreviewHeader
            sender={sender}
            documentsCount={documentsCount}
            verificationSMSRequired={smsMode}
          />

          <form onSubmit={handleSubmit(onSubmit)} {...rest}>
            <Card>
              <div className="downloadPassword">
                <div className="downloadPassword-introduction">
                  <Icon type="Password" sizeIcon="large" />
                  <div>
                    <p className="downloadPassword-title">
                      {t('exchangeFiles.passwordIntroduction')}
                    </p>
                    <p className="downloadPassword-information">
                      {t('exchangeFiles.passwordInformation', {
                        sender: sender.full_name,
                      })}
                    </p>
                  </div>
                </div>

                <div className="downloadPassword-input">
                  <label htmlFor="password">{t('exchangeFiles.label')}</label>
                  <Input
                    placeholder={t('exchangeFiles.passwordIntroduction')}
                    aria-label={t('exchangeFiles.label')}
                    register={register('password', {
                      required: {
                        value: true,
                        message: errorMessage as string,
                      },
                    })}
                    error={errorMessage}
                    autoFocus={autoFocus}
                  />
                </div>
              </div>
            </Card>

            {displayTos && (
              <CardLink
                cguLink={RoutePath.TOS}
                charteLink={RoutePath.PRIVACY_POLICY}
                onToggleChecked={handleToggle}
                isAccepted={conditionsAccepted}
              />
            )}

            <Button
              buttonType="submit"
              children={t('exchangeFiles.submit')}
              variantButton="tertiary"
              sizeButton="large"
              isDisabled={isDisabled}
              hasTooltip={isDisabled}
              tooltipText={
                (!conditionsAccepted &&
                  t('exchangeFiles.tooltip.conditionsNoAccepted')) ||
                t('exchangeFiles.passwordIntroduction')
              }
              tooltipIcon={'Forbid'}
              tooltipId="accept-conditions"
              fullWidth
            />
          </form>
        </>
      ) : (
        <DocumentError />
      )}
    </div>
  );
}

export default styled(DecryptionKeyUnlock)`
  display: flex;
  flex-direction: column;
  gap: inherit;

  .downloadPassword {
    display: flex;
    flex-direction: column;
    padding: ${convertPixelToRem(20)}rem;
    gap: ${convertPixelToRem(20)}rem;

    label {
      text-transform: uppercase;
      font-weight: 700;
      line-height: ${convertPixelToRem(26)}rem;
      letter-spacing: ${convertPixelToRem(-0.35)}rem;
    }
  }

  .downloadPassword-introduction {
    display: flex;
    gap: ${convertPixelToRem(10)}rem;
    align-items: center;

    .downloadPassword-title {
      font-weight: 700;
      line-height: ${convertPixelToRem(26)}rem;
      letter-spacing: ${convertPixelToRem(-0.35)}rem;
    }

    .downloadPassword-information {
      font-size: ${convertPixelToRem(14)}rem;
      line-height: ${convertPixelToRem(16)}rem;
      letter-spacing: ${convertPixelToRem(-0.39)}rem;
    }
  }

  .downloadPassword-input {
    display: flex;
    flex-direction: column;
    gap: ${convertPixelToRem(7)}rem;
    margin-bottom: ${convertPixelToRem(20)}rem;

    p {
      margin-left: ${convertPixelToRem(12)}rem;
      font-size: ${convertPixelToRem(10)}rem;
      line-height: ${convertPixelToRem(12)}rem;
    }
  }
`;
