import { Button, Card, CardLink, Icon, Input, styles } from '@ekino/serenly-ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ExchangeSender } from '../../clients/ApiClient';
import { IAttempts } from '../../services/AuthenticationService';
import { RoutePath } from '../routes/path';
import DownloadPreviewHeader from './DownloadPreviewHeader';

const { convertPixelToRem, color } = styles;

interface FormInputs {
  password?: string;
  verificationCode: string;
}
interface IdentityVerificationProps {
  sender: ExchangeSender;
  documentsCount?: number;
  verifyCodeHandler: (verificationCode: string) => void;
  sendCodeHandler: () => void;
  verificationInProgress: boolean;
  smsInitialized: boolean;
  verificationError?: string;
  attempts?: IAttempts;
  verificationSMSRequired: boolean;
}

function IdentityVerification({
  sender,
  documentsCount,
  verifyCodeHandler,
  sendCodeHandler,
  smsInitialized,
  verificationInProgress = false,
  verificationError,
  attempts,
  verificationSMSRequired,
  ...rest
}: IdentityVerificationProps) {
  const { t } = useTranslation();

  const [conditionsAccepted, setConditionsAccepted] = useState(false);

  const handleToggle = () => {
    setConditionsAccepted(!conditionsAccepted);
  };

  const schema = yup.object().shape({
    verificationCode: yup
      .string()
      .matches(/\d{6}/, t('forms.mustBe6Number'))
      .required(t('forms.required')),
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid, errors },
  } = useForm<{
    verificationCode: string;
  }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = async ({ verificationCode }: FormInputs) => {
    verifyCodeHandler(verificationCode);
  };

  return (
    <div {...rest}>
      {attempts &&
        attempts.failedAttemptsCount < attempts.failedAttemptsLimit && (
          <>
            <DownloadPreviewHeader
              sender={sender}
              documentsCount={documentsCount}
              verificationSMSRequired={verificationSMSRequired}
            />
            {!smsInitialized && (
              <>
                <CardLink
                  cguLink={RoutePath.TOS}
                  charteLink={RoutePath.PRIVACY_POLICY}
                  onToggleChecked={handleToggle}
                  isAccepted={conditionsAccepted}
                />

                <Button
                  buttonType="button"
                  fullWidth
                  children={t('exchangeFiles.sms.sendCode')}
                  variantButton="tertiary"
                  sizeButton="large"
                  isDisabled={!conditionsAccepted}
                  hasTooltip={!conditionsAccepted}
                  tooltipText={
                    (!conditionsAccepted &&
                      t('exchangeFiles.tooltip.conditionsNoAccepted')) ||
                    t('exchangeFiles.passwordIntroduction')
                  }
                  tooltipIcon={'Forbid'}
                  tooltipId="accept-conditions"
                  onClick={() => {
                    sendCodeHandler();
                  }}
                />
              </>
            )}

            {smsInitialized && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Card>
                  <div className="downloadSMS">
                    <div className="downloadSMS-introduction">
                      <Icon type="Sms" sizeIcon="large" />
                      <p className="downloadSMS-title">
                        {t('exchangeFiles.sms.sendCode')}
                      </p>
                    </div>

                    <div className="downloadSMS-input">
                      <div className="downloadSMS-label">
                        <label htmlFor="SMS">
                          {t('exchangeFiles.sms.label')}
                        </label>
                        <button className="downloadSMS-reSend">
                          {t('exchangeFiles.sms.reSend')}
                        </button>
                      </div>
                      <Input
                        placeholder={t('exchangeFiles.sms.label')}
                        aria-label={t('exchangeFiles.sms.label')}
                        register={register('verificationCode')}
                        required
                        error={
                          errors.verificationCode?.message || verificationError
                        }
                        disabled={verificationInProgress}
                      />
                    </div>
                  </div>
                </Card>

                <Button
                  buttonType="submit"
                  children={t('exchangeFiles.sms.submit')}
                  variantButton="tertiary"
                  sizeButton="large"
                  isDisabled={
                    !conditionsAccepted ||
                    isSubmitting ||
                    !isDirty ||
                    !isValid ||
                    verificationInProgress
                  }
                  hasTooltip={
                    !conditionsAccepted ||
                    isSubmitting ||
                    !isDirty ||
                    !isValid ||
                    verificationInProgress
                  }
                  tooltipText={
                    (!conditionsAccepted &&
                      t('exchangeFiles.tooltip.conditionsNoAccepted')) ||
                    t('exchangeFiles.sms.introduction')
                  }
                  tooltipIcon={'Forbid'}
                  tooltipId="accept-conditions"
                  fullWidth
                />
              </form>
            )}
          </>
        )}

      {attempts &&
        attempts.failedAttemptsCount >= attempts.failedAttemptsLimit && (
          <div className="download-unabled">
            <p>{t('exchangeDetails.error.unableToRetrieveExchange')}</p>
          </div>
        )}
    </div>
  );
}

export default styled(IdentityVerification)`
  display: flex;
  flex-direction: column;
  gap: inherit;

  form {
    display: flex;
    flex-direction: column;
    gap: inherit;
  }

  .downloadSMS {
    display: flex;
    flex-direction: column;
    padding: ${convertPixelToRem(20)}rem;
    gap: ${convertPixelToRem(20)}rem;

    label {
      text-transform: uppercase;
      font-weight: 700;
      line-height: ${convertPixelToRem(26)}rem;
      letter-spacing: ${convertPixelToRem(-0.35)}rem;
    }
  }

  .downloadSMS-introduction {
    display: flex;
    gap: ${convertPixelToRem(10)}rem;
    align-items: center;

    .downloadSMS-title {
      font-weight: 700;
      line-height: ${convertPixelToRem(26)}rem;
      letter-spacing: ${convertPixelToRem(-0.35)}rem;
    }

    .downloadSMS-information {
      font-size: ${convertPixelToRem(14)}rem;
      line-height: ${convertPixelToRem(16)}rem;
      letter-spacing: ${convertPixelToRem(-0.39)}rem;
    }
  }

  .downloadSMS-label {
    display: flex;
    justify-content: space-between;
  }

  .downloadSMS-reSend {
    border: none;
    background-color: inherit;
    font-size: ${convertPixelToRem(14)}rem;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 90%;
      height: 1px;
      background-color: ${color.darkGrey};
    }
  }

  .downloadSMS-input {
    display: flex;
    flex-direction: column;
    gap: ${convertPixelToRem(7)}rem;
    margin-bottom: ${convertPixelToRem(20)}rem;

    p {
      margin-left: ${convertPixelToRem(12)}rem;
      font-size: ${convertPixelToRem(10)}rem;
      line-height: ${convertPixelToRem(12)}rem;
    }
  }
`;
