import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';

type LoaderProps = {
  children?: React.ReactNode;
};

function Loader({ children, ...rest }: LoaderProps): JSX.Element {
  return (
    <div {...rest}>
      <Oval
        height={70}
        width={70}
        color="#ffffff"
        wrapperStyle={{}}
        wrapperClass="spinner"
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="rgba(255, 255, 255, 0.5)"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
      {children}
    </div>
  );
}

export default styled(Loader)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;

  .spinner {
    margin-bottom: 2rem;
  }
`;
