import { TransferProvider } from '../contexts/TransferContext';
import { UserStoreProvider } from '../contexts/UserContext';
import { BrowserRouter } from 'react-router-dom';
import TrackingProvider from './TrackingProvider';

type AppProviderProps = {
  children: React.ReactNode;
};

export default function AppProvider({
  children,
}: AppProviderProps): JSX.Element {
  return (
    <TrackingProvider>
      <UserStoreProvider>
        <TransferProvider>
          <BrowserRouter>{children}</BrowserRouter>
        </TransferProvider>
      </UserStoreProvider>
    </TrackingProvider>
  );
}
