import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppProvider from './provider/AppProvider';
import { styles } from '@ekino/serenly-ui';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import EN_US from './config/locales/en';
import FR_FR from './config/locales/fr';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: EN_US,
      },
      fr: {
        translation: FR_FR,
      },
    },
    lng: 'fr',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

document.documentElement.lang = i18n.language;

const { GlobalStyle } = styles;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <GlobalStyle />
    <AppProvider>
      <App />
    </AppProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
