import { CardDownload } from '@ekino/serenly-ui';
import { useTranslation } from 'react-i18next';
import { ExchangeSender } from '../../clients/ApiClient';
import { convertFileSize } from '../../helpers/ConvertSizeFile';

export interface DocumentEntry {
  id?: string;
  name: string;
  size: number;
}

export type DocumentsHandler = (
  documents: DocumentEntry[]
) => Promise<void> | undefined;

export interface DocumentListProps {
  sender: ExchangeSender;
  expirationDate?: string | Date;
  sendDate?: string | Date;
  documentsCount?: number;
  documents: DocumentEntry[];
  downloading: DocumentEntry[];
  removeHandler?: DocumentsHandler;
  downloadHandler: DocumentsHandler;
  scrollableContainerHeight?: string | number;
  isDownload?: boolean;
}

function DocumentList({
  sender,
  expirationDate,
  sendDate,
  documentsCount,
  documents,
  downloading,
  removeHandler,
  downloadHandler,
  isDownload,
}: DocumentListProps) {
  const { t } = useTranslation();

  const decryptedDocumentsFormat = documents?.map(document => {
    return {
      ...document,
      size: convertFileSize(document.size),
    };
  });

  return (
    <>
      <CardDownload
        title={t('downloadView.heading', {
          name: sender.full_name,
          count: documentsCount,
        })}
        sendDate={sendDate}
        endDate={expirationDate}
        fileList={decryptedDocumentsFormat as []}
        onDownloadFile={file => downloadHandler([file])}
        onDownloadAllFiles={files => downloadHandler(files)}
        isDownload={isDownload}
        loaderText={t('downloadView.loaderText')}
      />
    </>
  );
}

export default DocumentList;
