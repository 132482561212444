export const getFromStorage = <T>(storageKey: string): T | undefined => {
  const item = localStorage.getItem(storageKey);
  if (item) {
    try {
      return JSON.parse(item);
    } catch (e) {
      console.error(`Unable to parse '${storageKey}' item from localStorage`);
    }
  }
  return undefined;
};

export const setIntoStorage = <T>(
  storageKey: string,
  value: T | undefined
): void => {
  if (value) {
    localStorage.setItem(storageKey, JSON.stringify(value));
  } else {
    localStorage.removeItem(storageKey);
  }
};

export const clearStorage = () => {
  localStorage.clear();
};

export class StorageEntry<T> {
  private readonly storageKey: string;

  constructor(storageKey: string) {
    this.storageKey = storageKey;
  }

  load(): T | undefined {
    return getFromStorage<T>(this.storageKey);
  }

  save(value: T | undefined): void {
    return setIntoStorage<T>(this.storageKey, value);
  }
}
