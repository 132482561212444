import { useEffect, useState } from 'react';
import { LinkProps, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Modal, Button, Icon } from '@ekino/serenly-ui';
import { useTransfer } from '../../contexts/TransferContext';

interface TransferAwareLinkProps extends LinkProps {
  children?: React.ReactNode;
  modalText?: string;
}

export default function TransferAwareLink({
  children,
  to,
  modalText = 'Un transfert est en cours, voulez-vous continuer ?',
  ...rest
}: TransferAwareLinkProps): JSX.Element {
  const { transfer, resetTransfer, globalProgress } = useTransfer();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const handleClick = () => {
    if (transfer.files.length > 0 && globalProgress < 100) {
      setOpenModal(true);
    } else {
      navigate(to);
    }
  };

  useEffect(() => {
    if (!transfer.files?.length) setOpenModal(false);
  }, [transfer]);

  return (
    <>
      <a {...rest} onClick={handleClick}>
        {children}
      </a>
      <Modal
        open={openModal && !!transfer.files?.length}
        onClose={() => setOpenModal(false)}
        children={
          <>
            <Modal.Icon>
              <Icon type="Warning" sizeIcon="xlarge" />
            </Modal.Icon>
            <Modal.Item>
              <p>{modalText}</p>
            </Modal.Item>
          </>
        }
        actions={
          <Modal.Buttons>
            <Button
              variantButton="primary"
              children={t('uploadPage.modal.buttons.cancel')}
              onClick={() => setOpenModal(false)}
            />
            <Button
              variantButton="secondary"
              children={t('uploadPage.modal.buttons.gotIt')}
              onClick={() => {
                setOpenModal(false);
                resetTransfer();
                navigate(to);
              }}
            />
          </Modal.Buttons>
        }
      />
    </>
  );
}
