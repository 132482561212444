import { styles } from '@ekino/serenly-ui';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ExchangeReceiverAccess } from '../../clients/ApiClient';
import DownloadShareAuthenticator from '../receiver/DownloadShareAuthenticator';
import DownloadShareDetails from '../receiver/DownloadShareDetails';
import DocumentError from '../receiver/DocumentError';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { PAGES } from '../../constants/tracking';

const { convertPixelToRem } = styles;

type DownloadShareViewProps = {
  className?: string;
};

function DownloadShareView({ ...rest }: DownloadShareViewProps): JSX.Element {
  const { shareTokenParam } = useParams<{ shareTokenParam: string }>();
  const [exchangeAuthError, setExchangeAuthError] = useState<
    boolean | undefined
  >(undefined);
  const [exchangeAuth, setExchangeAuth] = useState<
    ExchangeReceiverAccess | undefined
  >(undefined);
  const [smsMode, setSmsMode] = useState<boolean>(false);
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: PAGES.DOWNLOAD,
    });
  }, [trackPageView]);
  return (
    <div {...rest}>
      {exchangeAuthError && <DocumentError isArchiveTransfer />}

      {!exchangeAuth && (
        <DownloadShareAuthenticator
          shareToken={shareTokenParam}
          onAuthenticationSuccess={setExchangeAuth}
          onAuthenticationError={() => setExchangeAuthError(true)}
          authenticatedViaSMS={setSmsMode}
        />
      )}

      {exchangeAuth && (
        <DownloadShareDetails smsMode={smsMode} authentication={exchangeAuth} />
      )}
    </div>
  );
}

export default styled(DownloadShareView)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: ${convertPixelToRem(30)}rem;
  z-index: 1;
`;
