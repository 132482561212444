import { ChangeEvent, useState, useEffect } from 'react';
import { useTransfer, ReceiverProps } from '../../contexts/TransferContext';
import { useNavigate, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import PasswordGenerator from 'generate-password';
import styled from 'styled-components';
import {
  Button,
  CardFormDate,
  CardFormPassword,
  CardFormReceiver,
  File,
  Icon,
  Input,
  Modal,
  Tabs,
} from '@ekino/serenly-ui';
import UploadLayout from '../layout/UploadLayout';
import { RoutePath } from '../routes';
import { useTranslation } from 'react-i18next';
import {
  isValidPhoneNumber,
  tryFormatPhoneNumber,
} from '../../helpers/PhoneHelpers';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import {
  CATEGORIES,
  ACTIONS,
  PAGES,
  DIMENSIONS,
} from '../../constants/tracking';

dayjs.extend(relativeTime);

interface IFormInputs {
  phoneNumber: string;
}

const UploadDetailsPage = ({ ...rest }) => {
  const { transfer, setTransfer } = useTransfer();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    getValues,
    setValue,
  } = useForm<IFormInputs>();

  const { files = [] } = transfer;
  const { trackEvent, trackPageView } = useMatomo();
  const [fileList, setFileList] = useState([...files]);
  const [fileSelected, setFileSelected] = useState('');
  const [selectedReceiver, setSelectedReceiver] = useState<
    ReceiverProps | undefined
  >();
  const [tabSelected, setTabSelected] = useState('link_tab');
  const [protectedByPassword, setProtectedByPassword] = useState(true);
  const [password, setPassword] = useState('');
  const [receiverList, setReceiverList] = useState<ReceiverProps[]>([]);
  const [transferName, setTransferName] = useState(
    fileList.length >= 1 ? fileList[0].name : ''
  );
  const [expirationDate, setExpirationDate] = useState<
    Date | null | undefined
  >();
  const [emailValue, setEmailValue] = useState<string>('');
  const [isModalDeleteFile, setModalDeleteFile] = useState(false);
  const [isModalDeleteReceiver, setModalDeleteReceiver] = useState(false);
  const [isModalAddPhoneNumber, setModalAddPhoneNumber] = useState(false);
  const [isModalDeletePhoneNumber, setModalDeletePhoneNumber] = useState(false);

  useEffect(() => {
    trackPageView({
      documentTitle: PAGES.UPLOAD_FORM,
    });
  }, [trackPageView]);

  const getReceiverById = (receiverId: string) =>
    receiverList.find(receiver => receiver.id === receiverId);

  const handleToggle = () => {
    setProtectedByPassword(!protectedByPassword);
  };

  const onPasswordChange = (e: { target: HTMLInputElement }) => {
    setPassword(e.target.value);
  };

  const onModalDeleteFile = (file: { name: string }) => {
    let fileIndex = fileList.findIndex(f => f.name === file.name);
    setFileSelected(fileList[fileIndex].name);
  };

  const handleDeleteFile = (fileName: string) => {
    setModalDeleteFile(false);
    setFileList(fileList.filter(file => file.name !== fileName));
  };

  const handleSelectFile = (filesToTransfer: File[]) => {
    // filters files to avoid duplicates
    const filteredFiles = filesToTransfer.filter(({ name, size, type }) => {
      return !fileList.find(file => {
        const hasSameName = file.name === name;
        const hasSameSize = file.size === size;
        const hasSameType = file.type === type;

        return hasSameName && hasSameSize && hasSameType;
      });
    });

    setFileList([...fileList, ...filteredFiles]);
  };

  const handleSelectTab = (tab: string) => {
    setTabSelected(tab);
  };

  const handleTransferNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTransferName(event.target.value);
  };

  const handleChangeEmail = (event: ChangeEvent<{ value: string }>) => {
    const pattern = /\S+@\S+\.\S+/;
    let emailInput = event.target.value;
    if (emailInput.match(pattern)) {
      setEmailValue(event.target.value);
    }
  };

  const onAddEmail = () => {
    const hasSameEmail = receiverList.find(
      receiver => receiver.email === emailValue
    );

    if (!hasSameEmail && emailValue) {
      setReceiverList([...receiverList, { id: emailValue, email: emailValue }]);
    }
  };

  const handleDeleteReceiver = (email: string) => {
    let newReceivers = receiverList.filter(
      receiver => receiver.email !== email
    );
    setReceiverList(newReceivers);
  };

  const handleAddOrUpdatePhoneNumber = (phoneNumber: string) => {
    const newReceiverList = receiverList.map(receiver => {
      if (receiver.email === selectedReceiver?.email) {
        return {
          ...receiver,
          phoneNumber: tryFormatPhoneNumber(phoneNumber),
        };
      }
      return receiver;
    });

    setReceiverList(newReceiverList);
    setModalAddPhoneNumber(false);
  };

  const handleDeletePhoneNumber = () => {
    const newReceiverList = receiverList.map(receiver => {
      if (receiver.email === selectedReceiver?.email) {
        return {
          ...receiver,
          phoneNumber: undefined,
        };
      }
      return receiver;
    });

    setReceiverList(newReceiverList);
    setModalDeletePhoneNumber(false);
  };

  const handleChangeDate = (value: any) => {
    let date = value as Date;

    if (typeof value === 'string') {
      const today = new Date();
      const addDays = parseInt(value);
      date = new Date(today.setDate(today.getDate() + addDays));
    }

    setExpirationDate(date);
  };

  const canTransferFile = () => {
    const hasTransferName = transferName !== '';
    const hasPasswordValue = protectedByPassword ? password !== '' : true;
    const hasReceiver =
      tabSelected === 'email_tab' ? receiverList.length > 0 : true;
    const hasExpirationDate = !!expirationDate;

    return (
      !hasTransferName ||
      !hasPasswordValue ||
      !hasExpirationDate ||
      !hasReceiver
    );
  };

  const handleGeneratePassword = () => {
    setPassword(
      PasswordGenerator.generate({
        length: 12,
        lowercase: true,
        uppercase: true,
        numbers: true,
        symbols: true,
        excludeSimilarCharacters: true,
        strict: false,
      })
    );
  };

  const handleGenerateLink = () => {
    setTransfer({
      ...transfer,
      files: [...fileList],
      protectedBy: {
        ...transfer.protectedBy,
        password: protectedByPassword,
      },
      password: password,
      receivers: tabSelected === 'link_tab' ? [] : receiverList,
      config: {
        ...transfer.config,
        download_expiration: expirationDate,
      },
      name: transferName,
    });

    trackEvent({
      category: CATEGORIES.TRANSFER,
      action: ACTIONS.CREATE,
      customDimensions: [
        {
          id: 1,
          value:
            tabSelected !== 'link_tab' && receiverList?.length > 0
              ? DIMENSIONS.MAIL
              : DIMENSIONS.LINK,
        },
        {
          id: 2,
          value: transfer.protectedBy?.sms
            ? DIMENSIONS.PROTECTED_BY_SMS
            : DIMENSIONS.NOT_PROTECTED_BY_SMS,
        },
        {
          id: 3,
          value: `${dayjs(expirationDate).diff(dayjs(), 'day')} days`,
        },
        {
          id: 4,
          value:
            tabSelected !== 'link_tab' && receiverList?.length > 0
              ? receiverList?.length.toString()
              : 'none',
        },
      ],
    });

    navigate(RoutePath.GENERATE_LINK);
  };

  if (!transfer.files.length) {
    return <Navigate to={RoutePath.HOME} replace />;
  }

  return (
    <div {...rest}>
      <UploadLayout isSendFileActive={true}>
        <div className="cards-wrapper">
          <div className="cards-wrapper-content">
            <File
              fileList={fileList}
              onSelectFile={handleSelectFile}
              onDeleteFile={file => {
                onModalDeleteFile(file);
                setModalDeleteFile(!isModalDeleteFile);
              }}
            />

            <Tabs
              tabs={[
                {
                  id: 'link_tab',
                  label: t('uploadPage.tabs.link'),
                  tabIcon: 'Link',
                },
                {
                  id: 'email_tab',
                  label: t('uploadPage.tabs.email'),
                  tabIcon: 'SendMail',
                },
              ]}
              isSelected
              selectedId={tabSelected}
              onClick={handleSelectTab}
            />

            <CardFormPassword
              header={t('uploadPage.password.header')}
              placeholderTransferName={`${t(
                'uploadPage.password.placeholderName'
              )}*`}
              onTransfertNameChange={handleTransferNameChange}
              placeholderPassword={`${t(
                'uploadPage.password.placeholderPassword'
              )}*`}
              disabled={false}
              onToggleCheck={handleToggle}
              passwordMandatory
              hasPassword={protectedByPassword}
              passwordValue={password}
              onPasswordChange={onPasswordChange}
              onClickIcon={handleGeneratePassword}
              transfertNameValue={transferName}
              onChange={event => console.log(event)}
            />

            {tabSelected === 'email_tab' && (
              <CardFormReceiver
                header={`${t('uploadPage.receiver.header')}*`}
                receivers={receiverList}
                onAddEmail={onAddEmail}
                onAddPhoneNumber={receiverId => {
                  const receiver = getReceiverById(receiverId);
                  setSelectedReceiver(receiver);
                  setValue('phoneNumber', receiver?.phoneNumber || '');
                  setModalAddPhoneNumber(true);
                }}
                onChangeEmail={handleChangeEmail}
                onUpdatePhoneNumber={receiverId => {
                  const receiver = getReceiverById(receiverId);
                  setSelectedReceiver(receiver);
                  setValue('phoneNumber', receiver?.phoneNumber || '');
                  setModalAddPhoneNumber(true);
                }}
                onDeletePhoneNumber={receiverId => {
                  setSelectedReceiver(getReceiverById(receiverId));
                  setModalDeletePhoneNumber(true);
                }}
                onDeleteReceiver={receiverId => {
                  setSelectedReceiver(getReceiverById(receiverId));
                  setModalDeleteReceiver(true);
                }}
              />
            )}

            <CardFormDate
              header={t('uploadPage.date.header')}
              inputName="endDateSpeedButtons"
              buttonList={[
                { id: '2', content: 'J+2', value: '2' },
                { id: '7', content: 'J+7', value: '7' },
                { id: '15', content: 'J+15', value: '15' },
                { id: '30', content: 'J+30', value: '30' },
              ]}
              defaultValue="7"
              onSelect={handleChangeDate}
              onChangeEndDate={handleChangeDate}
              endDateValue={expirationDate}
            />

            <Button
              variantButton="secondary"
              children={
                tabSelected === 'link_tab'
                  ? t('uploadPage.button.generate')
                  : t('uploadPage.button.transfer')
              }
              sizeButton="small"
              typeIcon="Check"
              isDisabled={canTransferFile()}
              onClick={handleGenerateLink}
              fullWidth
            />
          </div>
        </div>
      </UploadLayout>

      <form onSubmit={handleSubmit(data => console.log(data))}>
        <Modal
          open={isModalDeleteFile}
          onClose={() => setModalDeleteFile(false)}
          children={
            <>
              <Modal.Icon>
                <Icon type="Warning" sizeIcon="xlarge" />
              </Modal.Icon>
              <Modal.Item>
                <p>
                  {t('uploadPage.modal.textContent.deleteFile')}{' '}
                  <strong>{fileSelected}</strong> ?
                </p>
              </Modal.Item>
            </>
          }
          actions={
            <Modal.Buttons>
              <Button
                variantButton="primary"
                children={t('uploadPage.modal.buttons.cancel')}
                onClick={() => {
                  setModalDeleteFile(false);
                  setFileSelected('');
                }}
              />
              <Button
                variantButton="secondary"
                children={t('uploadPage.modal.buttons.delete')}
                onClick={() => handleDeleteFile(fileSelected)}
              />
            </Modal.Buttons>
          }
        />

        <Modal
          open={isModalDeleteReceiver}
          onClose={() => setModalDeleteReceiver(false)}
          children={
            <>
              <Modal.Icon>
                <Icon type="Warning" sizeIcon="xlarge" />
              </Modal.Icon>
              <Modal.Item>
                <p>
                  {t('uploadPage.modal.textContent.deleteEmail')} <br />
                  <strong>{selectedReceiver?.email}</strong> ?
                </p>
              </Modal.Item>
            </>
          }
          actions={
            <Modal.Buttons>
              <Button
                variantButton="primary"
                children={t('uploadPage.modal.buttons.cancel')}
                onClick={() => setModalDeleteReceiver(false)}
              />
              <Button
                variantButton="secondary"
                children={t('uploadPage.modal.buttons.delete')}
                onClick={() => {
                  handleDeleteReceiver(selectedReceiver?.email as string);
                  setModalDeleteReceiver(false);
                }}
              />
            </Modal.Buttons>
          }
        />

        <Modal
          open={isModalAddPhoneNumber}
          onClose={() => setModalAddPhoneNumber(false)}
          children={
            <>
              <Modal.Icon>
                <Icon type="Phone" sizeIcon="xlarge" />
              </Modal.Icon>
              <Modal.Item>
                <p>
                  {selectedReceiver?.phoneNumber
                    ? t('uploadPage.modal.textContent.modified')
                    : t('uploadPage.modal.textContent.addOne')}{' '}
                  {t('uploadPage.modal.textContent.phoneNumberFor')} <br />
                  <strong>{selectedReceiver?.email}</strong>
                </p>
              </Modal.Item>
              <Modal.MainTitle>
                {t('uploadPage.modal.textContent.phoneNumber')}
              </Modal.MainTitle>
              <Modal.Item>
                <Input
                  inputId="add-phone-number-receiver"
                  type="tel"
                  placeholder="0600000000"
                  register={register('phoneNumber', {
                    required: {
                      value: true,
                      message: t('uploadPage.modal.textContent.required'),
                    },
                    validate: {
                      valid: value => isValidPhoneNumber(value),
                    },
                  })}
                  error={
                    errors.phoneNumber?.type !== 'valid'
                      ? ''
                      : t('uploadPage.error.phoneNumber')
                  }
                />
                <p
                  style={{
                    fontSize: '12px',
                    marginTop: `${errors.phoneNumber?.type ? '20px' : '5px'}`,
                    marginBottom: '20px',
                  }}
                >
                  {t('uploadPage.modal.textContent.information')}
                </p>
              </Modal.Item>
              <Modal.Buttons>
                <Button
                  variantButton="primary"
                  children={t('uploadPage.modal.buttons.cancel')}
                  onClick={() => setModalAddPhoneNumber(false)}
                />
                <Button
                  variantButton="secondary"
                  children={`${
                    selectedReceiver?.phoneNumber
                      ? t('uploadPage.modal.buttons.edit')
                      : t('uploadPage.modal.buttons.add')
                  } ${t('uploadPage.modal.textContent.thePhoneNumber')}`}
                  onClick={async () => {
                    const result = await trigger('phoneNumber', {
                      shouldFocus: true,
                    });

                    if (result) {
                      handleAddOrUpdatePhoneNumber(getValues('phoneNumber'));
                    }
                  }}
                />
              </Modal.Buttons>
            </>
          }
        />

        <Modal
          open={isModalDeletePhoneNumber}
          onClose={() => setModalDeletePhoneNumber(false)}
          children={
            <>
              <Modal.Icon>
                <Icon type="Warning" sizeIcon="medium" />
              </Modal.Icon>
              <Modal.Item>
                <p>
                  {t('uploadPage.modal.textContent.deletePhoneNumber')}{' '}
                  {t('uploadPage.modal.textContent.for')}{' '}
                  <strong>{selectedReceiver?.email}</strong> ?
                </p>
              </Modal.Item>
            </>
          }
          actions={
            <Modal.Buttons>
              <Button
                variantButton="primary"
                children={t('uploadPage.modal.buttons.cancel')}
                onClick={() => setModalDeletePhoneNumber(false)}
              />
              <Button
                variantButton="secondary"
                children={t('uploadPage.modal.buttons.deleteNum')}
                onClick={handleDeletePhoneNumber}
              />
            </Modal.Buttons>
          }
        />
      </form>
    </div>
  );
};

export default styled(UploadDetailsPage)`
  .react-datepicker-popper {
    z-index: 110;
  }

  .cards-wrapper {
    display: grid;
    height: 100%;
    overflow: auto;
    padding: 15px 0;
  }

  .cards-wrapper-content {
    display: inline-grid;
    gap: 10px;
    margin: auto;

    > :first-child {
      width: auto;
    }
  }

  .tabs-wrapper {
    > :first-child {
      margin-right: 10px;
    }
  }
`;
