import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Icon, styles } from '@ekino/serenly-ui';
import { LogoEkino } from '../../assets/images';
import { ReactComponent as Logo } from '../../assets/images/send_logo.svg';

const { convertPixelToRem } = styles;

function SidebarLeft({ ...rest }) {
  const { t } = useTranslation();

  return (
    <div {...rest}>
      {/* <img src={sendLogo} alt="send logo" /> */}
      <Logo fill="black" />
      <section>
        <h1>{t('exchangeFiles.sidebarLeft.introduction')}</h1>
        <ul>
          {t<string, string[]>('exchangeFiles.sidebarLeft.list', {
            returnObjects: true,
          }).map(item => (
            <li key={item}>
              <span>
                <Icon type="Check" />
              </span>
              {item}
            </li>
          ))}
        </ul>
      </section>
      <LogoEkino opacity={'0.2'} />
    </div>
  );
}

export default styled(SidebarLeft)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${convertPixelToRem(90)}rem ${convertPixelToRem(80)}rem
    ${convertPixelToRem(80)}rem;

  background-color: #f1dc60;
  width: 30vw;
  max-width: 600px;
  section {
    display: flex;
    flex-direction: column;
    gap: ${convertPixelToRem(14)}rem;
    overflow: hidden;
  }

  ul {
    display: flex;
    flex-direction: column;
  }

  li {
    line-height: ${convertPixelToRem(28)}rem;
    display: flex;
    gap: ${convertPixelToRem(15)}rem;
  }
`;
