import { Routes, Route, Navigate } from 'react-router-dom';
import {
  authenticatedRoutes,
  RoutePath,
  commonRoutes,
  unauthenticatedRoutes,
} from './components/routes';
import AuthenticatedLayout from './components/layout/AuthenticatedLayout';

function AuthenticatedApp() {
  const routes = commonRoutes.concat(
    authenticatedRoutes,
    unauthenticatedRoutes
  );

  return (
    <AuthenticatedLayout>
      <Routes>
        {routes.map(route => {
          return (
            <Route
              index={RoutePath.HOME === route.path}
              path={route.path}
              element={<route.element />}
              key={route.name}
            />
          );
        })}
        <Route path="*" element={<Navigate to={RoutePath.HOME} />} />
      </Routes>
    </AuthenticatedLayout>
  );
}

export default AuthenticatedApp;
