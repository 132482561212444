import { useState, useEffect, useCallback } from 'react';
import {
  Tabs,
  TransfertInfoTitle,
  Date,
  Modal,
  Button,
  Icon,
} from '@ekino/serenly-ui';
import styled from 'styled-components';
import UploadLayout from '../layout/UploadLayout';
import {
  ExchangesResponse,
  ExchangeStatus,
  Exchange,
} from '../../clients/ApiClient';
import newAbortHelper, { AbortHelper } from '../../helpers/AbortHelpers';
import { useUserExchangeService } from '../../contexts/UserContext';
import TransferDetails from './TransferDetails';
import { useTranslation } from 'react-i18next';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { CATEGORIES, ACTIONS, PAGES } from '../../constants/tracking';

const TransferPage = ({ ...rest }) => {
  const exchangeService = useUserExchangeService();
  const { t } = useTranslation();
  const { trackEvent, trackPageView } = useMatomo();

  const [tabSelected, setTabSelected] = useState('latest_transfers');
  const [lastExchanges, setLastExchanges] = useState<
    ExchangesResponse | undefined
  >(undefined);
  const [archiveExchanges, setArchiveExchanges] = useState<
    ExchangesResponse | undefined
  >(undefined);
  const [exchangeToDelete, setExchangeToDelete] = useState<
    Exchange | undefined
  >(undefined);
  const [isModalArchiveTransfer, setModalArchiveTransfer] = useState(false);

  const refreshLastExchanges = useCallback(
    async (abortHelper: AbortHelper = newAbortHelper()) => {
      const result = await exchangeService.getExchanges({
        status: ExchangeStatus.DOWNLOADABLE,
      });
      abortHelper.doIfNotAborted(() => setLastExchanges(result));
    },
    [exchangeService]
  );

  const refreshArchiveExchanges = useCallback(
    async (abortHelper: AbortHelper = newAbortHelper()) => {
      const result = await exchangeService.getExchanges({
        status: ExchangeStatus.CLOSED,
      });
      abortHelper.doIfNotAborted(() => setArchiveExchanges(result));
      return result;
    },
    [exchangeService]
  );

  const refreshData = useCallback(() => {
    const abortHelper = newAbortHelper();
    refreshLastExchanges(abortHelper);
    refreshArchiveExchanges(abortHelper);
    return abortHelper.abort;
  }, [refreshLastExchanges, refreshArchiveExchanges]);

  const handleArchiveTransfer = useCallback(
    async (exchange: any) => {
      trackEvent({
        category: CATEGORIES.TRANSFER,
        action: ACTIONS.ARCHIVE,
      });

      if (exchange) {
        await exchangeService.archiveExchange(exchange.id);
        refreshData();
      }
    },
    [exchangeService, refreshData, trackEvent]
  );

  useEffect(refreshData, [refreshData]);

  useEffect(() => {
    trackPageView({
      documentTitle: PAGES.TRANSFER,
    });
  }, [trackPageView]);

  return (
    <UploadLayout {...rest} isTransferActive={true}>
      <div className="transfer_page_wrapper">
        <div className="tabs-wrapper">
          <Tabs
            tabs={[
              {
                id: 'latest_transfers',
                label: t('transferPageView.tabs.active'),
                tabNotif: true,
                tabNotifNumber: lastExchanges?.exchanges.length,
              },
              {
                id: 'archived_transfers',
                label: t('transferPageView.tabs.archive'),
                tabNotif: true,
                tabNotifNumber: archiveExchanges?.exchanges.length,
              },
            ]}
            isSelected
            selectedId={tabSelected}
            onClick={e => setTabSelected(e)}
          />
        </div>

        <div className="exchange-info-wrapper">
          {tabSelected === 'latest_transfers' &&
            lastExchanges?.exchanges.map((exchange: Exchange) => (
              <TransferDetails
                key={exchange.id}
                exchange={exchange}
                setModalArchiveTransfer={setModalArchiveTransfer}
                setExchangeToDelete={setExchangeToDelete}
              />
            ))}

          {tabSelected === 'archived_transfers' &&
            archiveExchanges?.exchanges.map((exchange: Exchange) => (
              <div className="archive-info-content" key={exchange.id}>
                <TransfertInfoTitle
                  title={exchange.name}
                  createDate={exchange.created_date}
                />
                <Date
                  status={exchange.status}
                  endDate={exchange.config?.download_expiration}
                />
              </div>
            ))}
        </div>
      </div>
      <Modal
        open={isModalArchiveTransfer}
        onClose={() => setModalArchiveTransfer(false)}
        children={
          <>
            <Modal.Icon>
              <Icon type="Warning" sizeIcon="xlarge" />
            </Modal.Icon>
            <Modal.Item>
              <p>{t('transferPageView.modal.text')}</p>
            </Modal.Item>
          </>
        }
        actions={
          <Modal.Buttons>
            <Button
              variantButton="primary"
              children={t('transferPageView.modal.buttons.cancel')}
              onClick={() => setModalArchiveTransfer(false)}
            />
            <Button
              variantButton="secondary"
              children={t('transferPageView.modal.buttons.archive')}
              onClick={() => {
                handleArchiveTransfer(exchangeToDelete);
                setModalArchiveTransfer(false);
              }}
            />
          </Modal.Buttons>
        }
      />
    </UploadLayout>
  );
};

export default styled(TransferPage)`
  .transfer_page_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    overflow: auto;
    padding: 20px 0;
  }

  .tabs-wrapper {
    display: flex;
    align-items: flex-end;
    li {
      margin-right: 20px;
    }
  }

  .exchange-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    max-height: 90vh;
    min-width: 750px;
  }

  .archive-info-content {
    display: flex;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(85px);
    padding: 1rem 1.5rem;
    border-radius: 35px;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s;
    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
`;
