import { useEffect } from 'react';
import { ExchangeReceiverAccess } from '../../clients/ApiClient';
import {
  useExchangeSharePreview,
  useShareAuthentication,
} from '../../hooks/exchangeAuthenticator';
import IdentityVerification from './IdentityVerification';

interface DownloadShareAuthenticatorProps {
  shareToken?: string;
  onAuthenticationSuccess: (access: ExchangeReceiverAccess) => void;
  authenticatedViaSMS: (val: boolean) => void;
  onAuthenticationError?: () => void;
}

function DownloadShareAuthenticator({
  shareToken,
  onAuthenticationSuccess,
  onAuthenticationError,
  authenticatedViaSMS,
}: DownloadShareAuthenticatorProps) {
  const [{ preview, isError: previewError }] =
    useExchangeSharePreview(shareToken);

  const [
    {
      verificationRequired,
      isLoading: authLoading,
      error: verificationError,
      access,
      initSms,
      attempts,
    },
    { verify, sendCode },
  ] = useShareAuthentication(shareToken);

  useEffect(() => {
    if (preview) {
      verify();
    }
  }, [shareToken, preview, verify]);

  useEffect(() => {
    if (access) {
      onAuthenticationSuccess(access);
    }
  }, [access, onAuthenticationSuccess]);

  useEffect(() => {
    authenticatedViaSMS(initSms);
  }, [authenticatedViaSMS, initSms]);

  useEffect(() => {
    if (previewError) {
      // eslint-disable-next-line no-unused-expressions
      onAuthenticationError?.();
    }
  }, [previewError, onAuthenticationError]);

  return (
    <>
      {preview && (
        <>
          {verificationRequired && (
            <IdentityVerification
              sender={preview.sender}
              documentsCount={preview.documents_count}
              // expirationDate={preview.config?.download_expiration}
              verifyCodeHandler={verify}
              smsInitialized={initSms}
              attempts={attempts}
              sendCodeHandler={sendCode}
              verificationInProgress={authLoading}
              verificationError={verificationError}
              verificationSMSRequired={verificationRequired}
            />
          )}
        </>
      )}
    </>
  );
}

export default DownloadShareAuthenticator;
