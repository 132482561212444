import { useCallback, useEffect, useState, ChangeEvent } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { Modal, Button, Icon, Input } from '@ekino/serenly-ui';
import { useUserExchangeService } from '../../contexts/UserContext';
import { useTransfer } from '../../contexts/TransferContext';
import { RoutePath } from '../routes/path';
import UploadLayout from '../layout/UploadLayout';
import { Exchange, ExchangeReceiver } from '../../clients/ApiClient';
import ExchangeFilesUpload from '../sender/ExchangeFileUpload';
import { useCopyToClipboard } from '../../hooks/copyToClipboard';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { CATEGORIES, ACTIONS, PAGES } from '../../constants/tracking';

const GenerateLinkPage = ({ ...rest }) => {
  const { transfer, cancelUpload, error, setTransfer } = useTransfer();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { trackEvent, trackPageView } = useMatomo();

  const { name, password, protectedBy, abortController } = transfer;
  const [isModalCancelUpload, setModalCancelUpload] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [isModalUploadCompleted, setModalUploadCompleted] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [downloadLink, setDownloadLink] = useState<string | undefined>('');
  const exchangeService = useUserExchangeService();
  const [exchange, setExchange] = useState<Exchange | undefined>(undefined);
  const [, copyToClipboard] = useCopyToClipboard();

  useEffect(() => {
    trackPageView({
      documentTitle: PAGES.UPLOAD,
    });
  }, [trackPageView]);

  const notifyCopyLink = () => {
    const link = t('generateLinkPage.toast.linkCopied');
    toast.success(link, {
      icon: () => <Icon sizeIcon="medium" type="Link" />,
    });
  };

  const notifyCopyPassword = () => {
    const link = t('generateLinkPage.toast.passwordCopied');
    return toast.success(link, {
      icon: () => <Icon sizeIcon="medium" type="Password" />,
    });
  };

  const filesSize = () => {
    const files = transfer.files;
    let size = 0;
    for (let i = 0; i < files.length; i++) {
      size += files[i].size;
    }
    let newSize = (size / 1000000).toFixed(1);
    return newSize;
  };

  const generateAccessToken = async () => {
    if (exchange) {
      const shareTokens = await exchangeService.getExchangeTokens(exchange.id);
      const token = await exchangeService.getAccessToken(
        exchange.id,
        shareTokens[0].id
      );

      setDownloadLink(
        `${window.location.origin}${RoutePath.DOWNLOAD_PATH}/${token.accessToken}`
      );
    }
  };

  /**
   * Transfer creation
   */
  const createExchange = useCallback(async () => {
    try {
      const receivers: ExchangeReceiver[] = transfer.receivers.map(
        ({ email, phoneNumber }) => {
          return {
            email: email.trim(),
            phone_number: phoneNumber ? phoneNumber : undefined,
          };
        }
      );
      setExchange(
        await exchangeService.createExchange({
          name: transfer.name,
          config: transfer.config,
          receivers,
        })
      );
    } catch (e) {
      console.error('createExchange.error.unableToCreateExchange', e);
    }
  }, [transfer, exchangeService]);

  useEffect(() => {
    createExchange();
  }, [createExchange]);

  const handleCopy = (value?: string) => {
    if (value) {
      copyToClipboard(value);
    }
  };

  useEffect(() => {
    error && setOpenErrorModal(true);
  }, [error]);

  const handleCancelUpload = () => {
    cancelUpload();
    trackEvent({
      category: CATEGORIES.TRANSFER,
      action: ACTIONS.CANCEL,
    });
    navigate(RoutePath.HOME);
  };

  const handleUploadComplete = () => {
    setUploadComplete(true);
    setModalUploadCompleted(true);
    trackEvent({
      category: CATEGORIES.TRANSFER,
      action: ACTIONS.COMPLETE,
    });
  };

  const handleAddFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      setTransfer({
        ...transfer,
        files: Array.from(files),
      });
    }

    navigate(RoutePath.UPLOAD_PAGE);
  };

  if (!transfer.files.length) {
    return <Navigate to={RoutePath.HOME} replace />;
  }

  return (
    <UploadLayout
      {...rest}
      isSendFileActive={!uploadComplete}
      onClickSendFile={handleAddFiles}
    >
      <div>
        {exchange?.id && (
          <ExchangeFilesUpload
            secret={password}
            documentCreateHandler={async fileMetadata => {
              const documentResponse = await exchangeService.createDocument(
                exchange.id,
                fileMetadata
              );
              return documentResponse.id;
            }}
            documentChunkCreateHandler={async (
              documentId,
              chunk,
              chunkIndex
            ) => {
              await exchangeService.uploadDocumentChunk(
                exchange.id,
                documentId,
                chunk,
                chunkIndex,
                abortController
              );
            }}
            contentDecryptionKeyHandler={async contentDecryptionKey => {
              await exchangeService.setContentDecryptionKey(
                exchange.id,
                contentDecryptionKey
              );
              generateAccessToken();
              handleUploadComplete();
            }}
            transferName={name}
            filesSize={`${filesSize()}MB`}
            setModalCancelUpload={() => setModalCancelUpload(true)}
          />
        )}
      </div>
      <Modal
        open={isModalCancelUpload}
        onClose={() => setModalCancelUpload(false)}
        children={
          <>
            <Modal.Icon>
              <Icon type="Warning" sizeIcon="xlarge" />
            </Modal.Icon>
            <Modal.Item>
              {t('generateLinkPage.modal.cancel')}
              <br /> <strong>{name}</strong> ?
            </Modal.Item>
          </>
        }
        actions={
          <Modal.Buttons>
            <Button
              variantButton="primary"
              children={t('generateLinkPage.modal.button.next')}
              onClick={() => {
                setModalCancelUpload(false);
              }}
            />
            <Button
              variantButton="secondary"
              children={t('generateLinkPage.modal.button.stop')}
              onClick={handleCancelUpload}
            />
          </Modal.Buttons>
        }
      />

      <Modal
        open={openErrorModal}
        onClose={() => setOpenErrorModal(false)}
        children={
          <>
            <Modal.Icon>
              <Icon type="Warning" sizeIcon="xlarge" />
            </Modal.Icon>
            <Modal.Item>{t('generateLinkPage.modal.error')}</Modal.Item>
          </>
        }
        actions={
          <Modal.Buttons>
            <Button
              variantButton="secondary"
              children={t('generateLinkPage.modal.button.ok')}
              onClick={handleCancelUpload}
            />
          </Modal.Buttons>
        }
      />

      <Modal
        open={isModalUploadCompleted}
        onClose={() => setModalUploadCompleted(false)}
        children={
          <>
            <Modal.Icon>
              <Icon type="Check_circle" sizeIcon="xlarge" />
            </Modal.Icon>
            <Modal.MainTitle>{name}</Modal.MainTitle>
            <Modal.SubTitle>
              {t('generateLinkPage.modal.success')}
            </Modal.SubTitle>
            {transfer.receivers.length === 0 && (
              <Modal.Item>
                <Input
                  id="link-input"
                  typeInsideIcon="Copy"
                  value={downloadLink}
                  readOnly
                  onClickIcon={() => {
                    handleCopy(downloadLink);
                    notifyCopyLink();
                  }}
                />
              </Modal.Item>
            )}
            {protectedBy.password && (
              <>
                <Modal.SubTitle>
                  {t('generateLinkPage.modal.password')}
                </Modal.SubTitle>
                <Modal.Item>
                  <Input
                    id="link-input"
                    typeInsideIcon="Copy"
                    value={password}
                    readOnly
                    onClickIcon={() => {
                      handleCopy(password);
                      notifyCopyPassword();
                    }}
                  />
                </Modal.Item>
              </>
            )}
          </>
        }
        actions={
          <Modal.Buttons>
            <Button
              variantButton="secondary"
              children={t('generateLinkPage.modal.button.new')}
              onClick={() => {
                navigate(RoutePath.HOME);
              }}
            />
          </Modal.Buttons>
        }
      />
    </UploadLayout>
  );
};

export default GenerateLinkPage;
