import { ExchangePreview } from '../clients/ApiClient';

interface ExchangePreviewFetchState {
  isLoading: boolean;
  isError: boolean;
  preview?: ExchangePreview;
}

type ExchangePreviewFetchAction =
  | { type: 'loading' }
  | { type: 'success'; payload: ExchangePreviewFetchState['preview'] }
  | { type: 'failure' };

const exchangePreviewFetchReducer = (
  state: ExchangePreviewFetchState,
  action: ExchangePreviewFetchAction
): ExchangePreviewFetchState => {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'success':
      return {
        ...state,
        isLoading: false,
        isError: false,
        preview: action.payload,
      };
    case 'failure':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      throw Error();
  }
};

export default exchangePreviewFetchReducer;
