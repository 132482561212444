import gPhoneNumber, { PhoneNumberFormat } from 'google-libphonenumber';

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();

const defaultRegionCode = 'FR';

export const isValidPhoneNumber = (
  source: string,
  regionCode: string = defaultRegionCode
): boolean => {
  try {
    const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(
      source,
      regionCode
    );
    if (!phoneUtil.isPossibleNumber(parsedPhoneNumber)) {
      return false;
    }
    return phoneUtil.isValidNumber(parsedPhoneNumber);
  } catch {
    return false;
  }
};

export const formatPhoneNumber = (
  source: string,
  regionCode: string = defaultRegionCode
): string => {
  const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(source, regionCode);
  return phoneUtil.format(parsedPhoneNumber, PhoneNumberFormat.INTERNATIONAL);
};

export const tryFormatPhoneNumber = (
  source: string,
  regionCode: string = defaultRegionCode
): string => {
  try {
    return formatPhoneNumber(source, regionCode);
  } catch {
    return source;
  }
};
