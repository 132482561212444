import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { ButtonCircle } from '@ekino/serenly-ui';
import { useTranslation } from 'react-i18next';

type UploadCircleButtonProps = {
  onChangeInputFile?: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  isActive?: boolean;
};

function UploadCircleButton({
  onChangeInputFile,
  isActive,
  className,
  ...rest
}: UploadCircleButtonProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <ButtonCircle
        {...rest}
        typeIcon="Send"
        variantButtonCircle="primary"
        isActive={isActive}
      >
        {t('uploadPage.button.sendFile')}
        <input
          className="input-file"
          type="file"
          onChange={onChangeInputFile}
          multiple
          disabled={isActive}
        />
      </ButtonCircle>
    </div>
  );
}

export default styled(UploadCircleButton)<UploadCircleButtonProps>`
  position: relative;

  button svg {
    fill: ${({ isActive }) => (isActive ? 'black' : 'white')};
  }

  button:hover svg {
    fill: black;
  }

  .input-file {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    border-radius: 85px;
    :hover {
      cursor: pointer;
    }
  }
`;
