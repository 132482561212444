import styled from 'styled-components';
import { LogoEkino } from '../../assets/images';

const Footer = ({ ...rest }) => (
  <div {...rest}>
    <LogoEkino />
  </div>
);

export default styled(Footer)`
  svg {
    right: 2rem;
    fill: white;
    width: 180px;
    position: absolute;
    bottom: 2rem;
  }
`;
