import { RoutePath } from './path';
import DownloadShareView from '../views/DownloadShareView';
import LegalNotice from '../views/LegalNotice';
import PrivacyPolicy from '../views/PrivacyPolicy';
import TermOfServices from '../views/TermOfServices';

export interface IRoute {
  name: string;
  path: RoutePath;
  element: React.ComponentType;
}

export const commonRoutes: IRoute[] = [
  {
    name: 'legal-notices',
    path: RoutePath.LEGAL_NOTICES,
    element: LegalNotice,
  },
  {
    name: 'terms-of-service',
    path: RoutePath.TOS,
    element: TermOfServices,
  },
  {
    name: 'privacy-policy',
    path: RoutePath.PRIVACY_POLICY,
    element: PrivacyPolicy,
  },
  {
    name: 'download',
    path: RoutePath.DOWNLOAD,
    element: DownloadShareView,
  },
];

export default commonRoutes;
