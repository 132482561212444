import { ChangeEvent } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ButtonCircle } from '@ekino/serenly-ui';
import { useTranslation } from 'react-i18next';
import TransferAwareLink from '../shared/TransferAwareLink';
import { RoutePath } from '../routes';
import UploadCircleButton from '../sender/UploadCircleButton';
import { useTransfer } from '../../contexts/TransferContext';
const UploadLayout = ({
  isSendFileActive,
  isTransferActive = false,
  children,
  ...rest
}: any) => {
  const { t } = useTranslation();
  const { setTransfer, transfer } = useTransfer();
  const navigate = useNavigate();

  const handleAddFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      setTransfer({
        ...transfer,
        files: Array.from(files),
      });
    }

    navigate(RoutePath.UPLOAD_PAGE);
  };

  return (
    <div {...rest}>
      <UploadCircleButton
        isActive={isSendFileActive}
        onChangeInputFile={handleAddFiles}
      />
      {children}
      <TransferAwareLink
        to={RoutePath.TRANSFERS}
        modalText={t('uploadPage.modal.textContent.transferInProgress')}
      >
        <ButtonCircle
          typeIcon="Transfert"
          variantButtonCircle="secondary"
          isActive={isTransferActive}
        >
          {t('uploadPage.button.myTransfer')}
        </ButtonCircle>
      </TransferAwareLink>
    </div>
  );
};

export default styled(UploadLayout)<{
  isTransferActive: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 0 2% 0 2%;

  .send-button-styled-wrapper {
    border-radius: 85px;
    ${({ isTransferActive }) =>
      isTransferActive &&
      css`
        svg {
          fill: white;
        }

        :hover svg {
          fill: black;
        }
      `}
  }
`;
