const Environment = {
  get appBrand(): string {
    return 'ekino';
  },
  get appName(): string {
    return 'Send';
  },
  get apiBaseUrl(): string {
    return process.env.REACT_APP_API_BASE_URL as string;
  },
  get googleClientId(): string {
    return process.env.REACT_APP_GOOGLE_CLIENT_ID as string;
  },
  cognito: {
    get region(): string {
      return process.env.REACT_APP_COGNITO_REGION as string;
    },
    get clientId(): string {
      return process.env.REACT_APP_COGNITO_CLIENT_ID as string;
    },
    get userPoolId(): string {
      return process.env.REACT_APP_COGNITO_USER_POOL_ID as string;
    },
    get domain(): string {
      return process.env.REACT_APP_COGNITO_DOMAIN as string;
    },
    get redirectUrl(): string {
      return process.env.REACT_APP_COGNITO_REDIRECT_URL as string;
    },
  },
};

export default Environment;
