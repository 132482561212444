import { useCallback, useEffect, useReducer, useState } from 'react';
import exchangePreviewFetchReducer from '../reducers/exchangePreviewFetchReducer';
import shareAuthenticationReducer from '../reducers/shareAuthenticationReducer';
import AuthenticationService from '../services/AuthenticationService';

export const useExchangeSharePreview = (
  initialShareToken: string | undefined
) => {
  const [shareToken, setShareToken] = useState(initialShareToken);

  const [state, dispatch] = useReducer(exchangePreviewFetchReducer, {
    isLoading: false,
    isError: false,
  });

  useEffect(() => {
    if (!shareToken) return;

    let didCancel = false;

    const fetchData = async () => {
      dispatch({ type: 'loading' });

      try {
        const { exchange_preview: preview } =
          await AuthenticationService.getExchangeSharePreview(shareToken);
        if (!didCancel) {
          dispatch({ type: 'success', payload: preview });
        }
      } catch (e) {
        if (!didCancel) {
          dispatch({ type: 'failure' });
        }
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    fetchData();

    return () => {
      didCancel = true;
    };
  }, [shareToken]);

  return [state, { setShareToken }] as const;
};

export const useShareAuthentication = (
  initialShareToken: string | undefined = undefined
) => {
  const [shareToken, setShareToken] = useState(initialShareToken);
  const [state, dispatch] = useReducer(shareAuthenticationReducer, {
    isLoading: false,
    verificationRequired: false,
    initSms: false,
  });

  const verify = useCallback(
    (verificationCode?: string) => {
      if (!shareToken) {
        return;
      }

      const fetchData = async () => {
        dispatch({ type: 'loading' });

        try {
          const { authentication, verificationAttempts } =
            await AuthenticationService.authorizeShareToken(
              shareToken,
              verificationCode
            );

          if (authentication) {
            dispatch({ type: 'success', access: authentication });
            return;
          }

          if (verificationCode) {
            dispatch({
              type: 'failure',
              error: `Invalid code - ${
                verificationAttempts.failedAttemptsLimit -
                verificationAttempts.failedAttemptsCount
              } remaining attempt`,
              attempts: verificationAttempts,
            });
          } else {
            dispatch({
              type: 'verification_required',
              attempts: verificationAttempts,
            });
          }
        } catch (e) {
          dispatch({ type: 'failure', error: 'An error occurred' });
        }
      };

      // noinspection JSIgnoredPromiseFromCall
      fetchData();
    },
    [shareToken]
  );

  const sendCode = useCallback(() => {
    if (!shareToken) {
      return;
    }

    dispatch({ type: 'initialize_sms' });

    AuthenticationService.sendSMSCode(shareToken)
      .then(({ verificationAttempts }) => {
        if (verificationAttempts.failedAttemptsCount > 0) {
          dispatch({
            type: 'failure',
            error: `Invalid code - ${
              verificationAttempts.failedAttemptsLimit -
              verificationAttempts.failedAttemptsCount
            } remaining attempt`,
            attempts: verificationAttempts,
          });
        } else {
          dispatch({
            type: 'verification_required',
            attempts: verificationAttempts,
          });
        }
      })
      .catch(() => {
        dispatch({ type: 'failure', error: 'An error occurred' });
      });
  }, [shareToken]);

  return [state, { setShareToken, verify, sendCode }] as const;
};
