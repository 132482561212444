import React, { useEffect, useState, useRef } from 'react';
import { ExchangeFilesUploadProps } from '../components/sender/ExchangeFileUpload';
import DocumentService from '../services/DocumentService';
import EncryptionService from '../services/EncryptionService';
import useUploadFile, { TUpload, UploadProgress } from '../hooks/uploadFile';

type TransferProviderProps = {
  children: React.ReactNode;
};

export type TTransfer = {
  name: string;
  files: File[];
  protectedBy: {
    password: boolean;
    sms: boolean;
  };
  password: string;
  receivers: ReceiverProps[];
  config: {
    download_expiration: string;
  };
  created_date: string;
  abortController: AbortController;
};

export interface IStartUpload
  extends Omit<
    ExchangeFilesUploadProps,
    'filesSize' | 'transferName' | 'setModalCancelUpload'
  > {}

export type TransferContextProps = {
  transfer: TTransfer;
  setTransfer: any;
  globalProgress: number;
  startUpload: (params: IStartUpload) => Promise<void>;
  resetTransfer: () => void;
  cancelUpload: () => void;
  retryUpload: () => void;
  error: boolean;
};

export type ReceiverProps = {
  id: string;
  email: string;
  phoneNumber?: string;
};

const TransferContext = React.createContext({} as TransferContextProps);

const initialTransfer = {
  name: '',
  files: [],
  protectedBy: {
    password: false,
    sms: false,
  },
  password: '',
  receivers: [],
  config: {
    download_expiration: '',
  },
  created_date: '',
};

function TransferProvider({ children }: TransferProviderProps) {
  const abortController = useRef(new AbortController());
  const [transfer, setTransfer] = useState<TTransfer>({
    ...initialTransfer,
    abortController: abortController.current,
  });
  const [globalProgress, setGlobalProgress] = useState(0);
  const [error, setError] = useState(false);
  const [uploads, setUploads] = useState<Map<File, TUpload>>(new Map());

  const { uploadFile, upload } = useUploadFile();

  useEffect(() => {
    if (upload) {
      // a current upload is in error
      // and we haven't aborted the upload yet
      if (
        upload.status === UploadProgress.ERROR &&
        !abortController.current.signal.aborted
      ) {
        cancelUpload();
        setError(true);
      } else {
        setUploads(prev => {
          const copy = new Map(prev);
          copy.set(upload.file, upload);
          return copy;
        });
      }
    }
  }, [upload]);

  useEffect(() => {
    if (uploads.size > 0) {
      let progress: number = 0;

      uploads.forEach(value => {
        progress += value.progress;
      });

      setGlobalProgress(Math.round(progress / uploads.size));
    }
  }, [uploads]);

  const startUpload = async ({
    secret,
    documentCreateHandler,
    documentChunkCreateHandler,
    contentDecryptionKeyHandler,
  }: IStartUpload) => {
    try {
      const { privateKey, publicKey } =
        await EncryptionService.generateKeyPair(secret);

      await Promise.all(
        transfer.files.map(file =>
          uploadFile({
            file,
            documentCreateHandler,
            documentChunkCreateHandler,
            publicKey,
          })
        )
      );

      await contentDecryptionKeyHandler(
        DocumentService.encodeContentDecryptionKey(privateKey)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const cancelUpload = () => {
    abortController.current.abort();
  };

  const resetTransfer = () => {
    if (uploads) {
      cancelUpload();
    }
    abortController.current = new AbortController();
    setTransfer({
      ...initialTransfer,
      abortController: abortController.current,
    });
    setUploads(new Map());
    setError(false);
    setGlobalProgress(0);
  };

  const retryUpload = () => {
    console.log('retry');
    setError(false);
    // TODO
    // - reset abortController
    // - separate chunks creation from uploading in EncryptionService
    // - reupload chunks with status ERROR and WAITING
    // Simpler solution
    // - delete transfer password
    // - navigate back to UploadDetailsPage
  };

  return (
    <TransferContext.Provider
      value={{
        transfer,
        setTransfer,
        startUpload,
        cancelUpload,
        globalProgress,
        resetTransfer,
        retryUpload,
        error,
      }}
    >
      {children}
    </TransferContext.Provider>
  );
}

function useTransfer() {
  const context = React.useContext(TransferContext);

  if (context === undefined) {
    throw new Error('useTransfer must be used within a TransferContext');
  }
  return context;
}

export { TransferProvider, useTransfer };
