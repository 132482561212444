import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@ekino/serenly-ui';
import { logoSend } from '../../assets/images';
import { useAuth } from '../../contexts/UserContext';
import TransferAwareLink from '../shared/TransferAwareLink';

const Header = ({ menuOpen, onToggleMenu, ...rest }: any) => {
  const { signOut } = useAuth();
  const { t } = useTranslation();

  return (
    <div {...rest}>
      <TransferAwareLink
        to="/"
        modalText={t(
          'uploadPage.modal.textContent.transferInProgressToHomepage'
        )}
      >
        <img src={logoSend} alt="logo send" />
      </TransferAwareLink>

      <div className="buttons">
        <button onClick={signOut} name="exit">
          <Icon type="Exit" sizeIcon="large" />
        </button>
        <button onClick={onToggleMenu} name="close">
          <Icon type={menuOpen ? 'Close' : 'Burger'} sizeIcon="large" />
        </button>
      </div>
    </div>
  );
};

export default styled(Header)`
  position: absolute;
  justify-content: space-between;
  width: 100%;
  height: 0;

  img {
    z-index: 10;
    position: absolute;
    left: 2rem;
    top: 2rem;
  }

  .buttons {
    z-index: 10;
    position: absolute;
    right: 2rem;
    top: 2rem;

    button {
      background: none;
      border: none;
      color: white;
    }
  }
`;
