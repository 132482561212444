import { base64Decode, base64Encode } from '../helpers/Base64Helpers';
import EncryptionService, { ChunkProcessor } from './EncryptionService';

export interface FileMetadata {
  filename: string;
  size: number;
}

const encryptFileMetadata = async (
  file: File,
  publicKey: string
): Promise<string> => {
  const fileMetadata: FileMetadata = {
    filename: file.name,
    size: file.size,
  };
  const encryptedMetadata = await EncryptionService.encryptText(
    publicKey,
    JSON.stringify(fileMetadata)
  );
  return base64Encode(encryptedMetadata);
};

const decryptFileMetadata = async (
  metadata: string,
  privateKey: string,
  secret?: string
): Promise<FileMetadata> => {
  const decodedMetadata = base64Decode(metadata);
  const decryptedMetadata = await EncryptionService.decryptText(
    privateKey,
    decodedMetadata,
    secret
  );
  return JSON.parse(decryptedMetadata);
};

const encryptDocument = async (
  file: File,
  publicKey: string,
  handleChunkUpload: ChunkProcessor
): Promise<void> => {
  await EncryptionService.encryptFile(publicKey, file, handleChunkUpload);
};

const encodeContentDecryptionKey = (rawDecryptionKey: string): string =>
  base64Encode(rawDecryptionKey);

const decodeContentDecryptionKey = (encodedDecryptionKey: string): string =>
  base64Decode(encodedDecryptionKey);

const DocumentService = {
  encryptFileMetadata,
  encryptDocument,
  decryptFileMetadata,
  encodeContentDecryptionKey,
  decodeContentDecryptionKey,
};

export default DocumentService;
