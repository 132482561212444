import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RoutePath } from '../routes';
import DisclaimerLayout from '../layout/DisclaimerLayout';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { PAGES } from '../../constants/tracking';

const TermOfServices = ({ ...rest }): JSX.Element => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: PAGES.TOS,
    });
  }, [trackPageView]);

  return (
    <DisclaimerLayout {...rest}>
      <header>
        <h1>
          Conditions générales d’utilisation de la plateforme SEND.EKINO.COM
        </h1>
        <p>Version : Mars 2021</p>
      </header>
      <section>
        <h2>Bienvenue sur la Plateforme SEND.EKINO.COM éditée par EKINO</h2>
        <p>
          <strong>EKINO</strong>, société par actions simplifiée au capital de
          296 000 €, dont le siège social est situé 157 rue Anatole France,
          92300 Levallois-Perret, immatriculée au Registre du Commerce et des
          sociétés de Nanterre sous le numéro 509 512 760, représentée par M.
          Malo GAUDRY, Directeur Général, a développé la Plateforme
          SEND.EKINO.COM (ci-après « <strong>Plateforme</strong> »).
        </p>
        <p>
          Les conditions d'accès et d'utilisation de la Plateforme sont
          encadrées dans le présent document (ci-après désignés les « Conditions
          »). Ce document constitue un contrat entre "<strong>Vous</strong>", («
          Utilisateur » en ce compris comme toute personne utilisant la
          Plateforme incluant l’Utilisateur Expéditeur et l’Utilisateur
          Destinataire), et "<strong>Nous</strong>", EKINO. Nous vous invitons à
          le lire attentivement pour connaître l'ensemble des conditions
          d'utilisation de la Plateforme, vos obligations ainsi que nos
          obligations et engagements. Ce document est accessible à tout moment
          sur la Plateforme.
        </p>
      </section>

      <section>
        <h2>Présentation générale de la plateforme</h2>
        <p>
          La Plateforme permet d’échanger entre Vous et Nous des documents de
          manière sécurisée, confidentielle et tracée sur la base du
          chiffrement.
        </p>
        <p>
          Les présentes Conditions définissent les obligations et engagements
          d’EKINO et de l'Utilisateur dans la mise à disposition et
          l'utilisation de la Plateforme.
        </p>
      </section>

      <section>
        <h2>Acceptation, opposabilité et modification des conditions</h2>
        <p>
          L'Utilisateur peut utiliser la Plateforme sous réserve de
          l'acceptation préalable des présentes Conditions. L'Utilisateur, en
          acceptant les présentes Conditions générales, déclare :
          <ul>
            <li>
              avoir connaissance des conditions dans lesquelles fonctionne la
              Plateforme ;
            </li>
            <li>
              disposer du matériel et de la connexion nécessaires pour accéder
              et utiliser la Plateforme en ligne et les services associés.
            </li>
          </ul>
        </p>
        <p>
          Les présentes Conditions sont opposables à l'Utilisateur dès la
          première utilisation, et leur acceptation formalisée par une case à
          cocher en ligne. Cette signature par clic constitue la preuve que
          l'Utilisateur a pris connaissance des présentes dispositions et il est
          réputé les avoir acceptées sans réserve.
        </p>
        <p>
          Les conditions générales d'utilisation sont susceptibles d’être
          modifiées ou aménagées à tout moment par EKINO. La nouvelle version du
          document sera notifiée à l'Utilisateur.
        </p>
      </section>

      <section>
        <h2>Accès et utilisation de la plateforme</h2>
        <h3>Prérequis techniques et information préalable</h3>
        <p>
          L'Utilisateur est informé que la connexion à la Plateforme s’effectue
          via le réseau Internet. Il est averti des aléas techniques qui peuvent
          affecter ce réseau et entraîner des ralentissements ou des
          indisponibilités rendant parfois la connexion impossible ou lente.
          L'Utilisateur accepte de prendre toutes les mesures appropriées de
          façon à protéger ses propres données et/ou logiciels de la
          contamination par d'éventuels virus sur le réseau Internet.
        </p>
        <p>
          L’Utilisateur est informé que Ekino met à disposition uniquement les
          moyens nécessaires à l’envoi sécurisé des documents. Ekino ne peut en
          aucun cas être tenu responsable au titre des contenus échangés via la
          Plateforme (ci-après « Contenu(s) »).
        </p>
        <p>
          L’Utilisateur est informé que la Plateforme est limitée à une
          utilisation strictement professionnelle.
        </p>
        <p>
          L’utilisation de la Plateforme est réservée aux personnes majeures
          disposant de la capacité juridique.
        </p>

        <h3>Accès et Identifiant Utilisateur</h3>
        <p>
          L’Utilisateur est informé que l’accès à la Plateforme se fait selon
          les conditions exposées ci-dessus.
        </p>

        <p>
          L’Utilisateur Expéditeur se connecte à la Plateforme en utilisant ses
          identifiants de connexion. Ces identifiants sont strictement
          personnels.
        </p>

        <p>
          S’il est reconnu comme étant un Utilisateur Expéditeur autorisé à
          accéder à la Plateforme, il aura alors la possibilité de créer un
          transfert de fichiers sécurisé par chiffrement.
        </p>
        <div>
          Pour télécharger le Contenu du transfert, l’Utilisateur Destinataire
          est invité à suivre la procédure définie ci-après en fonction de la
          nature du transfert :
          <ul>
            <li>
              Dans le cas d’un transfert nécessitant l’identification de
              l’Utilisateur Destinataire grâce à son numéro de téléphone :<br />
              Il accède au lien du transfert qui lui aura été envoyé par mail
              par la Plateforme. Après avoir pris connaissance et accepté les
              présentes Conditions et la Politique de Confidentialité EKINO,
              l’Utilisateur demande la génération d’un code unique qui lui sera
              envoyé par sms. Il s’identifie grâce à ce code et accède ensuite à
              la saisie du mot de passe permettant le téléchargement et le
              déchiffrement du contenu.
            </li>
            <li>
              Dans le cas d’un transfert ne nécessitant pas l’identification de
              l’Utilisateur Destinataire par son numéro de téléphone :
              <br />
              Il accède au lien du transfert qui lui aura été envoyé par mail
              par la Plateforme ou par un autre canal. Après avoir pris
              connaissance et accepté les présentes Conditions et la Politique
              de Confidentialité EKINO, l’Utilisateur pourra saisir le mot de
              passe permettant le téléchargement et le déchiffrement du contenu.
            </li>
          </ul>
        </div>
        <p>
          L'Utilisateur est seul responsable de sa connexion à la Plateforme et
          de l'utilisation de l’ensemble des identifiants tels que code unique
          ou mot de passe ou tout autre identifiant nécessaire pour utiliser la
          Plateforme et généré par la Plateforme (ci-après dénommés ensemble ou
          séparément « Identifiant »). Il est précisé que pour des raisons de
          sécurité, la Plateforme ne permet pas d’enregistrer les Identifiants.
          Il appartient donc à l’Utilisateur de conserver ces Identifiants par
          ses propres moyens et sous son unique responsabilité.
        </p>
        <p>
          EKINO ne pourra être tenu responsable de toute perte ou dommage
          résultant de l'utilisation par l'Utilisateur de la Plateforme ou de
          l'utilisation via un accès physique par un tiers (autorisé ou non) de
          la Plateforme.
        </p>
        <p>
          Toute perte, détournement ou utilisation non autorisée des
          Identifiants d’un Utilisateur et leurs conséquences relèvent de la
          seule responsabilité de l’Utilisateur, ce dernier étant tenu d’avertir
          EKINO sans délai, par message électronique à l’adresse suivante :
          security.send@ekino.com.
        </p>

        <h3>Transfert de Contenu, téléchargement et durée de validité</h3>
        <p>
          Dès réception du Contenu après le suivi de la procédure mentionnée au
          paragraphe <strong>Accès et Identifiant Utilisateur</strong>{' '}
          ci-dessus, l’Utilisateur peut télécharger le Contenu envoyé par EKINO.
        </p>
        <p>
          Le Contenu envoyé par la Plateforme reste disponible pendant une durée
          variable fixée par l’Expéditeur et d’une durée minimale de sept (7)
          jours à compter de l’envoi.
        </p>

        <h3>Coûts d’accès à la Plateforme</h3>
        <p>La Plateforme est utilisée par l’Utilisateur à titre gratuit.</p>
        <p>
          Tous les coûts afférents à l'accès à la Plateforme et à l’utilisation
          du réseau de télécommunication, que ce soient les frais matériels,
          logiciels ou d'accès à internet sont exclusivement à la charge de
          l'Utilisateur selon les modalités fixées par ses fournisseurs d’accès
          et opérateurs de télécommunication.
        </p>
      </section>

      <section>
        <h2>Obligations d’ekino</h2>
        <h3>Disponibilité de la Plateforme</h3>
        <p>
          EKINO s’engage à faire ses meilleurs efforts pour maintenir un accès à
          la Plateforme 24 heures sur 24 et 7 jours sur 7.
        </p>
        <p>
          EKINO s’engage également à faire ses meilleurs efforts, conformément
          aux règles de l'art, pour sécuriser la Plateforme eu égard à la
          complexité de l'Internet et s'engage à procéder régulièrement à des
          contrôles afin de vérifier son fonctionnement et son accessibilité.
        </p>
        <h3>Maintenance</h3>
        <p>
          L'accès à la Plateforme peut-être momentanément interrompu pour des
          raisons de nécessité liées à la maintenance de la Plateforme ou à la
          maintenance des serveurs d’EKINO ou de son sous-traitant chargé de
          l’hébergement.
        </p>
        <p>
          En cas de question, l’Utilisateur peut contacter EKINO à l’adresse de
          contact suivante : security.send@ekino.com.
        </p>
        <h3>Hébergement</h3>
        <p>
          Amazon assure le stockage des données et des fichiers à travers son
          service AMAZON WEB SERVICES et les serveurs sont localisés au sein de
          l’EEE.
        </p>
        <h3>Sécurité</h3>
        <p>
          EKINO met en œuvre des moyens destinés à assurer la sécurité de la
          Plateforme à travers des moyens de techniques de chiffrement qui font
          partie des plus hauts standards de l’industrie.
        </p>
        <div>
          EKINO peut vous assurer que les meilleurs moyens de sécuriser
          l’utilisation de la Plateforme pour le transfert sécurisé de Contenu
          sont mis en place :
          <ul>
            <li>
              Le Contenu est chiffré de bout en bout, depuis le navigateur de
              l'Utilisateur Expéditeur, jusqu'au navigateur de l’Utilisateur
              Destinataire. Il n'est pas possible de déchiffrer les Contenus sur
              la Plateforme.
            </li>
            <li>
              EKINO teste régulièrement les applications et l'infrastructure
              SEND.EKINO.COM pour identifier les éventuelles failles de
              sécurité, renforcer leur sécurité et les protéger contre les
              attaques.
            </li>
            <li>EKINO a mis en place un SMSI conforme à la norme ISO/27001.</li>
            <li>
              Amazon assure le stockage des données et des fichiers à travers
              son service AMAZON WEB SERVICES et les serveurs sont localisés au
              sein de l’EEE.
            </li>
            <li>
              Le lien du transfert devient inactif à la date d’expiration fixée
              par l’Expéditeur et par défaut, après sept (7) jours à compter de
              l’envoi. A la suite de cette expiration, les Contenus sont
              automatiquement supprimés de nos serveurs et les données de trafic
              liées au transfert archivées.
            </li>
            <li>
              Seules les personnes chez EKINO qui ont généré le transfert du
              Contenu ou qui sont habilitées pour des raisons de sécurité
              peuvent accéder à vos Données à caractère personnel.
            </li>
            <li>
              EKINO a mis en place différentes fonctionnalités afin de vous
              donner les moyens de sécuriser au mieux vos transferts de Contenu
              comme décrit à l’article « Accès et Identifiant Utilisateur ».
            </li>
          </ul>
        </div>
        <p>
          Néanmoins, EKINO ne maîtrise pas les risques liés au fonctionnement
          d'Internet et attire l’attention de l’Utilisateur sur l'existence
          d'éventuels risques de confidentialité des données transitant via ce
          réseau. En outre, EKINO ne saurait garantir une totale sécurité
          informatique.
        </p>
        <h3>Modification de la Plateforme</h3>
        <p>
          EKINO se réserve le droit, à tout moment, de modifier tout ou partie
          de la Plateforme et de ses fonctionnalités. Sauf disposition contraire
          expresse, toute nouvelle fonctionnalité qui améliore ou augmente la
          Plateforme sera soumise aux présentes Conditions.
        </p>
      </section>

      <section>
        <h2>Obligations de l’utilisateur</h2>
        <div>
          L’Utilisateur a pour obligation de :
          <ul>
            <li>
              se comporter de façon loyale et en bon père de famille à l’égard
              de EKINO et des tiers ;
            </li>
            <li>
              être honnête et sincère dans les informations fournies à EKINO ;
            </li>
            <li>
              utiliser le Plateforme conformément à son objet tel que décrit
              dans les présentes Conditions ;
            </li>
            <li>
              respecter la vie privée des tiers et la confidentialité des
              échanges ;
            </li>
            <li>de garantir EKINO de la légalité des Contenus ;</li>
            <li>
              de garantir d’une utilisation de la Plateforme conforme à ses
              règles internes (ex : Charte Informatique) et aux dispositions
              légales applicables dans son pays.
            </li>
          </ul>
        </div>

        <h3>Respect des dispositions légales et réglementaires</h3>

        <p>
          L'Utilisateur s'engage à utiliser la Plateforme dans le respect des
          dispositions légales en vigueur en France.
        </p>
        <div>
          Sont donc interdits :
          <ul>
            <li>
              tout comportement de nature à porter atteinte à l’image, la
              réputation et/ou les droits d’EKINO sur la Plateforme, notamment
              de propriété intellectuelle, ou des tiers ;
            </li>
            <li>
              envoyer ou recevoir des Contenus comprenant des logiciels,
              programmes, fichiers, vidéos, images ou messages dont le contenu
              est susceptible d’être illégal ou d’être contraire à l’ordre
              public ou aux bonnes mœurs ou de porter atteinte à la dignité
              d’autrui ainsi qu’à la protection des mineurs. Il est notamment
              strictement interdit d’envoyer des Contenus susceptibles d’être
              illicites, à caractère violent, injurieux, raciste, diffamatoire,
              érotique, pornographique, pédophile, révisionniste, , d’incitation
              à la haine ou à la violence ou à la commission d’actes illicites,
              faisant l’apologie du terrorisme, contrefaisants, ou manifestement
              contraires à l’ordre public ou aux bonnes mœurs, à des fins de
              harcèlement ou de menace ou tout site ou message prônant la
              discrimination notamment sur la base du genre, de l’orientation
              sexuelle, de l’origine nationale ou ethnique ou des convictions
              politiques ou religieuses d’une personne ou d’un groupe de
              personnes ;
            </li>
            <li>
              utiliser la Plateforme dans le cadre activité illégale ou
              illicite, quelle qu’elle soit ;
            </li>
            <li>
              utiliser la Plateforme à des fins de propagande électorale ou
              syndicale ou ayant pour objet la diffusion d’idéologies politiques
              ;
            </li>
            <li>usurper l’identité d’un tiers ;</li>
            <li>
              tout acte de malveillance ou abus à l’encontre du Site ou d’EKINO,
              ou tout acte susceptible d’être malveillant ou de constituer un
              abus. A cet égard, peuvent constituer de tels actes :
            </li>
            <li>
              le piratage de la Plateforme ou l’introduction d’un code
              malveillant, tel qu’un virus, un cheval de troie, ou tout autre
              dispositif susceptible de nuire à la Plateforme ou à tout système
              d’exploitation ;
            </li>
            <li>
              extraire des données ou des informations de la Plateforme ou des
              serveurs d’EKINO ;
            </li>
            <li>
              affecter l’intégrité et la performance de la Plateforme et de ses
              données ;
            </li>
            <li>
              accéder ou tenter d’accéder à la Plateforme, à ses systèmes ou
              réseaux associés, sans autorisation.
            </li>
          </ul>
        </div>
        <p>
          Nous vous invitons pour les pays autres que la France à vous
          renseigner sur les dispositions légales spécifiques qui seraient
          susceptibles de s’appliquer.
        </p>
      </section>

      <section>
        <h2>Droits de propriété intellectuelle</h2>
        <p>
          La Plateforme et chacun des éléments qui le composent (tels que les
          textes, photographies, marques telles que la marque EKINO, logos,
          illustrations, animations, représentations graphiques, schémas, clips,
          vidéos, noms de domaines, charte graphique utilisée, slogan, base de
          données etc…) sont protégés dans le monde entier par les droits de
          propriété intellectuelle et les droits de la personnalité en vigueur
          ou toute autre législation nationale internationale en vigueur, et
          sont, sauf mention contraire, la propriété exclusive d’EKINO ou sont
          utilisés dans le cadre d’un contrat de licence qui lui a été concédé
          par leur titulaire ou avec l’autorisation de leur titulaire ou
          conformément aux termes de la licence open source.
        </p>
        <p>
          L’Utilisateur s'engage à respecter les droits de propriété
          intellectuelle d’EKINO ainsi que, le cas échéant, les droits des
          tiers.
        </p>
        <p>
          L’Utilisateur dispose sur la Plateforme et l’ensemble de ses
          composants tels que visés ci-dessus d'un droit d'accès et
          d'utilisation non exclusif et temporaire, dans les conditions prévues
          dans les présentes Conditions.
        </p>
        <p>
          Tout agissement allant au-delà de ces droits d'accès et d'utilisation
          et notamment toute reproduction, téléchargement illicite ou mise à
          disposition de tiers est interdit et ne pourra être effectuée sans
          l’autorisation expresse et préalable d’EKINO.
        </p>
      </section>

      <section>
        <h2>Protection des données à caractère personnel et cookies</h2>
        <p>
          Dans le cadre des présentes Conditions, EKINO traite des données à
          caractère personnel des Utilisateurs conformément à sa{' '}
          <Link to={RoutePath.PRIVACY_POLICY}>
            Politique de confidentialité
          </Link>
          .
        </p>
        <p>
          La Plateforme ne dépose aucun cookie sur le terminal de l’Utilisateur
        </p>
      </section>

      <section>
        <h2>Confidentialité et secret des correspondances</h2>
        <h3>Confidentialité</h3>
        <p>
          Sont considérées comme strictement confidentielles toutes informations
          de quelque nature que ce soit qui seront communiquées par EKINO à
          l’Utilisateur via la Plateforme (« Informations Confidentielles »).
        </p>
        <p>
          Chacune des Parties s’engage à garder secrète et confidentielle toute
          Information Confidentielle transmise par l’autre Partie et à prendre
          toutes mesures nécessaires pour en préserver la confidentialité à
          l’égard de leurs personnels ou de toutes autres personnes se trouvant
          sous sa responsabilité.
        </p>
        <h3>Secret des correspondances</h3>
        <p>
          Conformément à la réglementation applicable, EKINO ainsi que les
          membres de son personnel s’engagent à respecter le secret des
          correspondances étant précisé que le secret couvre le contenu de la
          correspondance, l'identité des correspondants ainsi que, le cas
          échéant, l'intitulé du message et les documents joints à la
          correspondance. En aucun cas, EKINO ne peut voir sa responsabilité
          civile ou pénale engagée à raison de Contenus transmis par la
          Plateforme.
        </p>
      </section>

      <section>
        <h2>Responsabilité</h2>
        <h3>Principes généraux</h3>
        <p>
          EKINO est responsable en tant qu’éditeur de la Plateforme permettant
          l’envoi chiffré de documents vers l’Utilisateur Destinataire.
        </p>
        <p>
          EKINO s’engage à apporter tous les soins nécessaires à l’exécution des
          services via la Plateforme conformément aux règles de l’art
          applicables aux présentes et ne peut en aucun cas être soumis à une
          obligation de résultat quant à la fourniture desdits services.
        </p>
        <p>
          L’Utilisateur reconnaît que la Plateforme est fournie « en l’état » ou
          « as is » sans aucune garantie. EKINO ne saurait être responsable pour
          le Contenu transféré via la Plateforme ou toute utilisation qui en est
          faite par l’Utilisateur. En outre, EKINO ne saurait être tenu
          responsable de la licéité des données, documents, informations ou
          images des Contenus.
        </p>
        <p>
          En toutes hypothèses, la responsabilité d’EKINO ne pourra être engagée
          que pour les dommages directs et prévisibles subis par l’Utilisateur
          et résultant d’un manquement prouvé aux obligations contractuelles de
          EKINO. La responsabilité d’EKINO ne pourra en aucun cas être engagée
          pour les dommages indirects tels que notamment les pertes
          d’opportunités commerciales, les pertes de chiffre d’affaires, les
          préjudices d’image ou d’atteinte à la réputation, la perte de données.
          La responsabilité d’EKINO est limitée à 15 000 euros.
        </p>

        <h3>Limitation de responsabilité</h3>
        <div>
          L’Utilisateur reconnaît expressément que la responsabilité de EKINO ne
          peut en aucun cas être recherchée :
          <ul>
            <li>
              en cas de suspension de l'accès à la Plateforme pour des
              opérations de maintenance technique;
            </li>
            <li>
              pour des performances réduites ou une indisponibilité temporaire
              ou permanente de la Plateforme ;
            </li>
            <li>
              en cas de survenance de tout dommage résultant d'une faute, d’une
              utilisation non conforme de la Plateforme ou d'une négligence de
              l'Utilisateur ;
            </li>
            <li>
              pour toute défaillance du réseau Internet ou des moyens de
              communication ;
            </li>
            <li>
              en cas de force majeure telle que définie par la jurisprudence ou
              cause étrangère non imputable à EKINO.
            </li>
          </ul>
        </div>
        <p>
          EKINO n’offre aucune garantie, expresse ou tacite, concernant tout ou
          partie du Contenu de la Plateforme et/ou son fonctionnement technique.
        </p>
        <p>
          En cas d’utilisation non conforme de la Plateforme aux présentes
          Conditions, l’Utilisateur est seul responsable des dommages causés aux
          tiers et des conséquences, réclamations ou actions engendrées.
        </p>
      </section>

      <section>
        <h2>Assurances</h2>
        <p>
          EKINO est couvert par une assurance responsabilité civile exploitation
          et professionnelle et une assurance spécifique CYBER, d’un montant
          minimum de 5 (cinq) millions d’euros chacune, pour tous les dommages
          qu’ils pourraient causer à l’Utilisateur lors de l’utilisation de la
          Plateforme
        </p>
      </section>

      <section>
        <h2>Dispositions générales</h2>
        <p>
          Vous consentez, sans réserve, à accéder et utiliser ce Site à vos
          risques et périls et vous engagez à respecter la réglementation en
          vigueur dans votre pays, notamment dans l’hypothèse où l’accès à la
          Plateforme et/ou l’utilisation de son contenu serait interdit(e) à
          certaines personnes.
        </p>

        <p>
          Le fait qu’EKINO n’ait pas exigé l’application d’une clause quelconque
          des Conditions ne pourra en aucun cas être considéré comme une
          renonciation aux droits d’EKINO découlant de ladite clause, ni comme
          une renonciation à faire valoir l’application d’une autre clause.
        </p>

        <p>
          Dans l’hypothèse où l’une quelconque des clauses des présentes
          Conditions serait déclarée nulle, réputée non écrite ou invalide,
          cette clause serait réputée détachable des autres clauses des
          Conditions et n’affecterait pas leur validité.
        </p>
      </section>

      <section>
        <h2>Loi applicable et juridictions compétentes</h2>
        <p>
          La Plateforme ainsi que ses Conditions sont disponibles en langue
          française et anglaise. Il est précisé que la version française des
          présentes Conditions prévaudra en cas de contradiction sur leur
          version en anglais.
        </p>
        <p>Elles sont régies et interprétées conformément au droit français.</p>
        <p>
          EN CAS DE LITIGE AFFÉRENT À L'ACCÈS ET L’UTILISATION DU SITE,
          COMPETENCE EXPRESSE EST ATTRIBUEE AU TRIBUNAL COMPÉTENT DU RESSORT DE
          LA COUR D’APPEL DE PARIS, NONOBSTANT PLURALITE DE DEFENDEURS OU APPEL
          EN GARANTIE, MÊME POUR LES PROCEDURES D’URGENCE OU POUR LES PROCEDURES
          CONSERVATOIRES, EN REFERE OU PAR REQUÊTE.
        </p>
      </section>

      <section>
        <h2>Contact</h2>
        <p>
          Pour toute question, nous vous invitons à contacter EKINO aux
          coordonnées mentionnées dans les{' '}
          <Link to={RoutePath.LEGAL_NOTICES}>Informations légales</Link>.
        </p>
      </section>
    </DisclaimerLayout>
  );
};

export default TermOfServices;
