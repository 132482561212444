import axios, { AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import Environment from '../config/Environment';
import { typedArrayToBuffer } from '../helpers/FileHelpers';
import { UserRole } from '../stores/UserStore';

export interface AuthenticationToken {
  access_token: string;
  expiration_date: string;
}

export interface InternalAuthenticationRequest {
  id_token: string;
}

export interface ShareTokenPreviewRequest {
  share_token: string;
}

export interface ExchangePreview {
  id: string;
  name?: string;
  sender: ExchangeSender;
  config?: ExchangeConfig;
  documents_count: number;
}

export interface ExchangeSharePreviewResponse {
  exchange_preview: ExchangePreview;
}

export interface AuthorizeShareTokenRequest {
  share_token: string;
  verification_code?: string;
}

export interface ExchangeReceiverAccess {
  exchange_id: string;
  token: AuthenticationToken;
}

export enum VerificationStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
}

export interface ExchangeShareAuthenticationResponse {
  verification_status?: VerificationStatus;
  authentication?: ExchangeReceiverAccess;
}

const client = axios.create({ baseURL: Environment.apiBaseUrl });
axiosRetry(client, { retries: 0 });

const authenticateInternalUser = (
  data: InternalAuthenticationRequest
): Promise<AxiosResponse<AuthenticationToken>> =>
  client.post<AuthenticationToken>('/tokens/internal', data);

const getExchangeSharePreview = (
  data: ShareTokenPreviewRequest
): Promise<AxiosResponse<ExchangeSharePreviewResponse>> =>
  client.post<ExchangeSharePreviewResponse>(
    '/tokens/exchange-share/preview',
    data
  );

const authorizeShareToken = (
  data: AuthorizeShareTokenRequest
): Promise<AxiosResponse<ExchangeShareAuthenticationResponse>> =>
  client.post<ExchangeShareAuthenticationResponse>(
    '/tokens/exchange-share/authorize',
    data
  );

const sendSMSCode = (
  data: AuthorizeShareTokenRequest
): Promise<AxiosResponse<ExchangeShareAuthenticationResponse>> =>
  client.post<ExchangeShareAuthenticationResponse>(
    '/tokens/exchange-share/verify/send-code',
    data
  );

export enum UserType {
  INTERNAL_USER = 'INTERNAL_USER',
  EXCHANGE_RECEIVER = 'EXCHANGE_RECEIVER',
}

export interface AuthenticationProfileResponse {
  id: string;
  type: UserType;
  role: UserRole;
}

const getUserProfile = (authToken: string): Promise<any> =>
  client.get<AuthenticationProfileResponse>('/tokens/profile', {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

export interface PaginationParams {
  limit?: number;
  offset?: number;
}

export interface ExchangeSender {
  email: string;
  full_name?: string;
}

export interface ExchangeReceiver {
  id?: string;
  email: string;
  phone_number?: string;
}

export interface ExchangeConfigInput {
  /**
   * Duration
   * ex: 'P1D'
   */
  download_expiration: string;
}

export interface ExchangeCreateRequest {
  name?: string;
  receivers: Partial<ExchangeReceiver>[];
  config?: ExchangeConfigInput;
}

export enum ExchangeStatus {
  OPEN = 'OPEN',
  DOWNLOADABLE = 'DOWNLOADABLE',
  CLOSED = 'CLOSED',
}

export interface ExchangeConfig {
  download_expiration: string;
}

export interface Exchange {
  id: string;
  name?: string;
  status: ExchangeStatus;
  receivers: ExchangeReceiver[];
  sender: ExchangeSender;
  content_decryption_key?: string;
  config?: ExchangeConfig;
  created_date: string;
}

export interface PaginationData {
  offset: number;
  limit: number;
  total: number;
}

export interface ExchangesGetRequest extends PaginationParams {
  status?: ExchangeStatus;
}

export interface ExchangesResponse {
  pagination: PaginationData;
  exchanges: Exchange[];
}

export enum AccessUpdateModes {
  'ENABLE' = 'ENABLE',
  'DISABLE' = 'DISABLE',
}

export interface AccessStatusUpdateBody {
  reason: string;
}

const getExchanges = (
  authToken: string,
  request: ExchangesGetRequest
): Promise<any> =>
  client.get<ExchangesResponse>('/exchanges', {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    params: request,
  });

const createExchange = (
  authToken: string,
  data: ExchangeCreateRequest
): Promise<any> =>
  client.post<Exchange>('/exchanges', data, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

const getExchange = (authToken: string, exchangeId: string): Promise<any> =>
  client.get<Exchange>(`/exchanges/${exchangeId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

const archiveExchange = (authToken: string, exchangeId: string): Promise<any> =>
  client.post(`/exchanges/${exchangeId}/archive`, undefined, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

export interface ExchangeDocumentCreateRequest {
  metadata: string;
}

export interface ExchangeDocumentResponse {
  id: string;
  metadata: string;
}

const createDocument = (
  authToken: string,
  exchangeId: string,
  data: ExchangeDocumentCreateRequest
): Promise<any> =>
  client.post<ExchangeDocumentResponse>(
    `/exchanges/${exchangeId}/documents`,
    data,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );

const getDocuments = (authToken: string, exchangeId: string): Promise<any> =>
  client.get<ExchangeDocumentResponse[]>(`/exchanges/${exchangeId}/documents`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

const uploadDocumentChunk = (
  authToken: string,
  exchangeId: string,
  documentId: string,
  chunkIndex: number,
  data: Uint8Array | Uint16Array | Uint32Array,
  abortController: AbortController
): Promise<any> => {
  return client.put(
    `/exchanges/${exchangeId}/documents/${documentId}/chunks/${chunkIndex}`,
    typedArrayToBuffer(data),
    {
      signal: abortController.signal,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/octet-stream',
      },
      'axios-retry': {
        retries: 3,
      },
    }
  );
};

const getDocumentChunks = (
  authToken: string,
  exchangeId: string,
  documentId: string
): Promise<any> =>
  client.get<number[]>(
    `/exchanges/${exchangeId}/documents/${documentId}/chunks`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );

const downloadDocumentChunk = (
  authToken: string,
  exchangeId: string,
  documentId: string,
  chunkIndex: number
): Promise<any> =>
  client.get<ArrayBuffer>(
    `/exchanges/${exchangeId}/documents/${documentId}/chunks/${chunkIndex}`,
    {
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Accept-Type': 'application/octet-stream',
      },
      'axios-retry': {
        retries: 3,
      },
    }
  );

export interface ExchangeContentDescriptionKeyRequest {
  decryption_key: string;
}

const setContentDecryptionKey = (
  authToken: string,
  exchangeId: string,
  data: ExchangeContentDescriptionKeyRequest
): Promise<any> =>
  client.put<ExchangeDocumentResponse>(
    `/exchanges/${exchangeId}/set-content-decryption-key`,
    data,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );

export interface ExchangeShareToken {
  id: string;
  identityVerificationRequired: boolean;
  enabled: boolean;
  receiver: ExchangeReceiver;
  stats: {
    identityVerificationAttempts: {
      failedAttemptsCount: number;
      failedAttemptsLimit: number;
    };
    contentUnlockAttempts: {
      failedAttemptsCount: number;
      failedAttemptsLimit: number;
    };
  };
}

const getShareTokens = (authToken: string, exchangeId: string): Promise<any> =>
  client.get<any>(`/exchanges/${exchangeId}/accesses`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

const eventUnlockAttempt = (
  authToken: string,
  exchangeId: string,
  status: { status: 'SUCCESS' | 'FAIL' }
): Promise<any> =>
  client.post(
    `/exchanges/${exchangeId}/events/content-unlock-attempts`,
    status,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );

const eventDocumentRetrieved = (
  authToken: string,
  exchangeId: string,
  documentId: string
): Promise<void> =>
  client.post(
    `/exchanges/${exchangeId}/events/document-retrieved`,
    { document_id: documentId },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );

const getAccessToken = (
  authToken: string,
  exchangeId: string,
  accessId: string
): Promise<any> =>
  client.post<AuthenticationToken>(
    `/exchanges/${exchangeId}/accesses/${accessId}/token`,
    {},
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );

const getUsers = (authToken: string, params: unknown): Promise<any> =>
  client.get('/users', {
    params,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

const updateUser = (
  authToken: string,
  userId: string,
  params: unknown
): Promise<any> =>
  client.put(`/users?userId=${userId}`, params, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

const updateAccessActivationStatus = (
  authToken: string,
  exchangeId: string,
  accessId: string,
  mode: AccessUpdateModes,
  data: AccessStatusUpdateBody
): Promise<any> =>
  client.put<AuthenticationToken>(
    `/exchanges/${exchangeId}/accesses/${accessId}/${mode.toLowerCase()}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );

const ApiClient = {
  authenticateInternalUser,
  getExchangeSharePreview,
  authorizeShareToken,
  sendSMSCode,
  getUserProfile,
  getExchanges,
  createExchange,
  getExchange,
  archiveExchange,
  createDocument,
  getDocuments,
  getDocumentChunks,
  uploadDocumentChunk,
  downloadDocumentChunk,
  setContentDecryptionKey,
  getShareTokens,
  eventUnlockAttempt,
  eventDocumentRetrieved,
  getAccessToken,
  updateAccessActivationStatus,
  getUsers,
  updateUser,
};

export default ApiClient;
