import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';
import { ReactNode } from 'react';

const instance = createInstance({
  urlBase: 'https://ekino.matomo.cloud/',
  siteId: 1,
  // userId: 'UID76903202', // optional, default value: `undefined`.
  disabled: process.env.NODE_ENV === 'development',
});

type TrackingProviderProps = {
  children: ReactNode;
};

export default function TrackingProvider({ children }: TrackingProviderProps) {
  return <MatomoProvider value={instance}>{children}</MatomoProvider>;
}
