import ApiClient, {
  ExchangeReceiverAccess,
  ExchangeSharePreviewResponse,
} from '../clients/ApiClient';
import { TAppAuth, TUserProfile, TUserType } from '../stores/UserStore';

export interface IAttempts {
  failedAttemptsCount: number;
  failedAttemptsLimit: number;
}

interface IAuthorizeShareToken {
  authentication?: ExchangeReceiverAccess;
  verificationStatus: string;
  verificationAttempts: IAttempts;
}

const formatAttempts = (data: any = {}): IAttempts => {
  return {
    failedAttemptsCount: data.failed_attempts_count || 0,
    failedAttemptsLimit: data.failed_attempts_limit || -1,
  };
};

const formatAuthorizeShareToken = (data: any = {}): IAuthorizeShareToken => {
  return {
    authentication: data.authentication,
    verificationStatus: data.verification_status || '',
    verificationAttempts: formatAttempts(data.verification_attempts),
  };
};

const authenticateInternalUser = (idToken: string): Promise<TAppAuth> =>
  ApiClient.authenticateInternalUser({
    id_token: idToken,
  }).then(result => ({
    accessToken: result.data.access_token,
    expirationDate: result.data.expiration_date,
  }));

const getExchangeSharePreview = async (
  shareToken: string
): Promise<ExchangeSharePreviewResponse> => {
  const { data } = await ApiClient.getExchangeSharePreview({
    share_token: shareToken,
  });
  return data;
};

const authorizeShareToken = (
  shareToken: string,
  verificationCode?: string
): Promise<IAuthorizeShareToken> =>
  ApiClient.authorizeShareToken({
    share_token: shareToken,
    verification_code: verificationCode,
  }).then(result => formatAuthorizeShareToken(result.data));

const sendSMSCode = (shareToken: string): Promise<IAuthorizeShareToken> =>
  ApiClient.sendSMSCode({
    share_token: shareToken,
  }).then(result => formatAuthorizeShareToken(result.data));

const getUserProfile = (authToken: string): Promise<TUserProfile> => {
  return ApiClient.getUserProfile(authToken).then(result => {
    const { data } = result;
    return {
      id: data.id,
      type: data.type as TUserType,
      role: data.role,
    };
  });
};

const AuthenticationService = {
  authenticateInternalUser,
  getExchangeSharePreview,
  authorizeShareToken,
  getUserProfile,
  sendSMSCode,
};

export default AuthenticationService;
