import { useEffect, useState } from 'react';
import {
  ExchangeDocumentResponse,
  ExchangeSender,
} from '../../clients/ApiClient';
import newAbortHelper from '../../helpers/AbortHelpers';
import useExchangeService from '../../hooks/exchangeService';
import DocumentService from '../../services/DocumentService';
import ExchangeService from '../../services/ExchangeService';
import DecryptionKeyUnlock from './DecryptionKeyUnlock';
import DocumentList, { DocumentEntry } from './DocumentList';

export type ExchangeDocumentsManager = Pick<
  ExchangeService,
  'downloadDocument'
>;

interface DownloadDocumentsDisplayProps {
  sender: ExchangeSender;
  expirationDate?: string;
  sendDate?: string;
  documentsCount?: number;
  displayTos?: boolean;
  accessToken: string;
  exchangeId: string;
  encodedDecryptionKey: string;
  documentsResponse: ExchangeDocumentResponse[];
  exchangeManager: ExchangeDocumentsManager;
  autoFocusOnPassword?: boolean;
  smsMode: boolean;
}

function DownloadDocumentsDisplay({
  sender,
  expirationDate,
  sendDate,
  documentsCount,
  exchangeId,
  accessToken,
  encodedDecryptionKey,
  documentsResponse,
  exchangeManager,
  autoFocusOnPassword,
  displayTos = false,
  smsMode,
}: DownloadDocumentsDisplayProps) {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [downloading, setDownloading] = useState<DocumentEntry[]>([]);

  const [isDownload, setDownload] = useState<boolean>(false);

  const [decodedDecryptionKey, setDecodedDecryptionKey] = useState<
    string | undefined
  >(undefined);

  const [contentDecryptionPassword, setContentDecryptionPassword] = useState<
    string | undefined
  >(undefined);
  const [decryptedDocuments, setDecryptedDocuments] = useState<
    DocumentEntry[] | undefined
  >(undefined);

  const [exchangeService] = useExchangeService(accessToken);

  useEffect(() => {
    const abortHelper = newAbortHelper();
    if (encodedDecryptionKey) {
      const result =
        DocumentService.decodeContentDecryptionKey(encodedDecryptionKey);
      abortHelper.doIfNotAborted(() => setDecodedDecryptionKey(result));
    } else {
      abortHelper.doIfNotAborted(() => setDecodedDecryptionKey(undefined));
    }
    return abortHelper.abort;
  }, [encodedDecryptionKey]);

  useEffect(() => {
    const abortHelper = newAbortHelper();
    (async () => {
      if (
        documentsResponse &&
        decodedDecryptionKey &&
        contentDecryptionPassword
      ) {
        const result: DocumentEntry[] = await Promise.all(
          documentsResponse.map(encryptedDoc => {
            return DocumentService.decryptFileMetadata(
              encryptedDoc.metadata,
              decodedDecryptionKey,
              contentDecryptionPassword
            ).then(fileMetadata => ({
              id: encryptedDoc.id,
              name: fileMetadata.filename,
              size: fileMetadata.size,
            }));
          })
        );
        abortHelper.doIfNotAborted(() => {
          setDecryptedDocuments(result);
        });
      }
    })();
    return abortHelper.abort;
  }, [documentsResponse, decodedDecryptionKey, contentDecryptionPassword]);

  const downloadFiles = async (documents: DocumentEntry[]) => {
    console.log(isDownload);
    if (decodedDecryptionKey && contentDecryptionPassword) {
      setDownload(true);
      setDownloading(downloading.concat(documents));
      try {
        // eslint-disable-next-line no-restricted-syntax
        for (const document of documents.filter(doc => doc.id !== undefined)) {
          await exchangeManager.downloadDocument(
            exchangeId,
            document.id!,
            document.name,
            decodedDecryptionKey,
            contentDecryptionPassword
          );

          exchangeService.notifyDocumentDownload(exchangeId, document.id!);
          setDownloading(downloading.filter(item => item.id === document.id!));
        }
      } catch (e) {
        console.error('An error occurred during the download.', e);
        setErrorMessage('An error occurred during the download.');
        setDownloading([]);
      }
      console.log(isDownload);
      setDownload(false);
    }
  };

  return (
    <>
      {errorMessage && <p>{errorMessage}</p>}

      {decodedDecryptionKey && !contentDecryptionPassword && (
        <DecryptionKeyUnlock
          sender={sender}
          documentsCount={documentsCount}
          displayTos={displayTos}
          accessToken={accessToken}
          exchangeId={exchangeId}
          autoFocus={autoFocusOnPassword}
          privateKeyArmored={decodedDecryptionKey}
          onValidPassword={setContentDecryptionPassword}
          smsMode={smsMode}
        />
      )}

      {contentDecryptionPassword && !decryptedDocuments && <p>Unlocking...</p>}

      {decryptedDocuments && (
        <DocumentList
          sender={sender}
          expirationDate={expirationDate}
          sendDate={sendDate}
          documentsCount={documentsCount}
          documents={decryptedDocuments}
          downloadHandler={downloadFiles}
          downloading={downloading}
          isDownload={isDownload}
        />
      )}
    </>
  );
}

export default DownloadDocumentsDisplay;
