import { RoutePath } from './path';
import LoginPage from '../views/LoginPage';
import { IRoute } from './commonRoutes';

export const unauthenticatedRoutes: IRoute[] = [
  {
    name: 'login',
    path: RoutePath.LOGIN,
    element: LoginPage,
  },
];

export default unauthenticatedRoutes;
