import * as openpgp from 'openpgp';
import Bottleneck from 'bottleneck';
import { chunkFile } from '../helpers/FileHelpers';

export const CHUNK_SIZE = 2621440; // 2621440 = 25MB
const maxConcurrentChunks = 10;

export interface KeyPair {
  privateKey: string;
  publicKey: string;
}

const generateKeyPair = async (
  password: string,
  name = 'send ekino user',
  email = 'someone@send.ekino.com'
): Promise<KeyPair> => {
  const { privateKey, publicKey } = await openpgp.generateKey({
    userIDs: [
      {
        name,
        email,
      },
    ],
    rsaBits: 2048,
    passphrase: password,
  });

  return {
    privateKey,
    publicKey,
  };
};

const encryptText = async (
  publicKeyArmored: string,
  text: string
): Promise<string> => {
  const publicKey = await openpgp.readKey({ armoredKey: publicKeyArmored });

  const encrypted = await openpgp.encrypt({
    message: await openpgp.createMessage({ text }),
    config: {
      preferredCompressionAlgorithm: openpgp.enums.compression.zip,
    },
    encryptionKeys: publicKey,
  });

  return encrypted;
};

const hasValidPrivateKeyPassword = async (
  privateKeyArmored: string,
  passphrase: string
): Promise<boolean> => {
  try {
    const privateKey = await openpgp.decryptKey({
      privateKey: await openpgp.readPrivateKey({
        armoredKey: privateKeyArmored,
      }),
      passphrase,
    });

    return privateKey.isPrivate();
  } catch (e) {
    return false;
  }
};

const decryptText = async (
  privateKeyArmored: string,
  encryptedText: string,
  passphrase?: string
): Promise<string> => {
  const privateKey = await openpgp.decryptKey({
    privateKey: await openpgp.readPrivateKey({ armoredKey: privateKeyArmored }),
    passphrase,
  });

  const decrypted = await openpgp.decrypt({
    message: await openpgp.readMessage({
      armoredMessage: encryptedText,
    }),
    decryptionKeys: privateKey,
  });

  const chunks = [];

  for await (const chunk of decrypted.data) {
    chunks.push(chunk);
  }

  return chunks.join('');
};

export type ChunkContent = Uint8Array | Uint16Array | Uint32Array;

export type ChunkProcessor = (
  chunk: ChunkContent,
  chunkIndex: number,
  totalChunksCount: number
) => Promise<void>;

const encryptFile = async (
  publicKeyArmored: string,
  file: Blob,
  chunkProcessor: ChunkProcessor
): Promise<void> => {
  const publicKey = await openpgp.readKey({ armoredKey: publicKeyArmored });

  const readChunk = async (chunk: Blob, index: number, totalCount: number) =>
    new Promise<void>(resolve => {
      const fileReader: FileReader = new FileReader();

      fileReader.onload = async () => {
        const ciphertext = await openpgp.encrypt({
          message: await openpgp.createMessage({
            binary: new Uint8Array(fileReader.result as ArrayBuffer),
          }),
          encryptionKeys: publicKey,
          config: {
            preferredCompressionAlgorithm: openpgp.enums.compression.zip,
          },
          format: 'object',
        });

        const encryptedChunk = ciphertext.packets.write();

        await chunkProcessor(encryptedChunk, index, totalCount);
        resolve();
      };
      fileReader.readAsArrayBuffer(chunk);
    });

  const limiter = new Bottleneck({
    maxConcurrent: maxConcurrentChunks,
  });

  const chunks = chunkFile(file, CHUNK_SIZE);
  const totalCount = chunks.length;
  await Promise.all(
    chunks.map((chunk: Blob, index: number) =>
      limiter.schedule(() => readChunk(chunk, index, totalCount))
    )
  );
};

const decryptFile = async (
  privateKeyArmored: string,
  passphrase: string,
  chunksCollector: ArrayBuffer[]
): Promise<Promise<any>[]> => {
  const privateKey = await openpgp.decryptKey({
    privateKey: await openpgp.readPrivateKey({ armoredKey: privateKeyArmored }),
    passphrase,
  });

  return chunksCollector.map(async chunkData => {
    const data = await openpgp.decrypt({
      message: await openpgp.readMessage({
        binaryMessage: new Uint8Array(await chunkData),
      }),
      decryptionKeys: privateKey,
      format: 'binary',
    });
    return data.data;
  });
};

const EncryptionService = {
  generateKeyPair,
  encryptText,
  hasValidPrivateKeyPassword,
  decryptText,
  encryptFile,
  decryptFile,
};

export default EncryptionService;
